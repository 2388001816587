// import React from 'react';
// import { XYPlot, LineSeries, XAxis, YAxis, HorizontalGridLines } from 'react-vis';

// const Timeline = ({ logs }) => {
//   const data = logs.map((log, index) => ({
//     x: index,
//     y: log.message.includes('ERROR') ? 1 : 0, // 1 for ERROR, 0 for INFO
//   }));

//   return (
//     <div className="mb-6">
//       <XYPlot height={100} width={800}>
//         <HorizontalGridLines />
//         <LineSeries data={data} />
//         <XAxis />
//         <YAxis />
//       </XYPlot>
//     </div>
//   );
// };

// export default Timeline;

// import React from 'react';
// import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

// const Timeline = ({ logs }) => {
//   const data = logs.map((log, index) => ({
//     x: index,
//     y: log.message.includes('ERROR') ? 1 : 0,
//   }));

//   return (
//     <div className="mb-6">
//       <ResponsiveContainer width="100%" height={150}>
//         <LineChart data={data}>
//           <CartesianGrid strokeDasharray="3 3" />
//           <XAxis dataKey="x" />
//           <YAxis />
//           <Tooltip />
//           <Line type="monotone" dataKey="y" stroke="#8884d8" />
//         </LineChart>
//       </ResponsiveContainer>
//     </div>
//   );
// };

// export default Timeline;


// import React from 'react';
// import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
// import moment from 'moment'; // For datetime formatting

// const Timeline = ({ logs }) => {
//   // Step 1: Group logs by timestamp (e.g., per hour) and count the number of logs
//   const groupedLogs = logs.reduce((acc, log) => {
//     const timestamp = moment(log.timestamp).local().startOf('hour').format('YYYY-MM-DD HH:mm'); // Group by hour
//     if (!acc[timestamp]) {
//       acc[timestamp] = { timestamp, count: 0, errors: 0 };
//     }
//     acc[timestamp].count += 1;
//     if (log.message.includes('ERROR')) {
//       acc[timestamp].errors += 1;
//     }
//     return acc;
//   }, {});

//   // Step 2: Convert the grouped logs into an array for the chart
//   const data = Object.values(groupedLogs).map((group) => ({
//     timestamp: group.timestamp,
//     count: group.count,
//     errors: group.errors,
//   }));

//   // Step 3: Sort data by timestamp (oldest first)
//   data.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

//   return (
//     <div className="mb-6">
//       <h3 className="text-lg font-semibold mb-2">Log Timeline</h3>
//       <ResponsiveContainer width="100%" height={300}>
//         <LineChart data={data}>
//           <CartesianGrid strokeDasharray="3 3" />
//           <XAxis
//             dataKey="timestamp"
//             tickFormatter={(timestamp) => moment(timestamp).local().format('MMM D, HH:mm')} // Format datetime
//           />
//           <YAxis />
//           <Tooltip
//             labelFormatter={(timestamp) => moment(timestamp).local().format('MMM D, HH:mm')} // Format tooltip datetime
//           />
//           <Line
//             type="monotone"
//             dataKey="count"
//             name="Total Logs"
//             stroke="#8884d8"
//             strokeWidth={2}
//           />
//           <Line
//             type="monotone"
//             dataKey="errors"
//             name="Errors"
//             stroke="#ff7300"
//             strokeWidth={2}
//           />
//         </LineChart>
//       </ResponsiveContainer>
//     </div>
//   );
// };

// export default Timeline;



import React, { useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import moment from 'moment'; // For datetime formatting

const Timeline = ({ logs }) => {
  const [timeInterval, setTimeInterval] = useState('hour'); // Default time interval

  // Function to group logs by the selected time interval
  const groupLogsByInterval = (logs, interval) => {
    return logs.reduce((acc, log) => {
      let timestamp;
      switch (interval) {
        case 'minute':
          timestamp = moment(log.timestamp).local().startOf('minute').format('YYYY-MM-DD HH:mm');
          break;
        case 'hour':
          timestamp = moment(log.timestamp).local().startOf('hour').format('YYYY-MM-DD HH:mm');
          break;
        case 'day':
          timestamp = moment(log.timestamp).local().startOf('day').format('YYYY-MM-DD');
          break;
        case '7days':
          timestamp = moment(log.timestamp).local().startOf('day').format('YYYY-MM-DD');
          break;
        case 'month':
          timestamp = moment(log.timestamp).local().startOf('month').format('YYYY-MM');
          break;
        default:
          timestamp = moment(log.timestamp).local().startOf('hour').format('YYYY-MM-DD HH:mm');
      }

      if (!acc[timestamp]) {
        acc[timestamp] = { timestamp, count: 0, errors: 0 };
      }
      acc[timestamp].count += 1;
      if (log.message.includes('ERROR')) {
        acc[timestamp].errors += 1;
      }
      return acc;
    }, {});
  };

  // Step 1: Group logs by the selected time interval
  const groupedLogs = groupLogsByInterval(logs, timeInterval);

  // Step 2: Convert the grouped logs into an array for the chart
  const data = Object.values(groupedLogs).map((group) => ({
    timestamp: group.timestamp,
    count: group.count,
    errors: group.errors,
  }));

  // Step 3: Sort data by timestamp (oldest first)
  data.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

  // Step 4: Filter data for the last 7 days if the interval is '7days'
  const filteredData = timeInterval === '7days'
    ? data.filter((entry) => moment(entry.timestamp).isAfter(moment().subtract(7, 'days')))
    : data;

  return (
    <div className="mb-6">
      <h3 className="text-lg font-semibold mb-2">Log Timeline</h3>

      {/* Time Interval Dropdown */}
      <div className="mb-4">
        <label htmlFor="timeInterval" className="mr-2">Group by:</label>
        <select
          id="timeInterval"
          value={timeInterval}
          onChange={(e) => setTimeInterval(e.target.value)}
          className="p-2 border border-gray-300 rounded-lg"
        >
          <option value="minute">Minute</option>
          <option value="hour">Hour</option>
          <option value="day">Day</option>
          <option value="7days">Last 7 Days</option>
          <option value="month">Month</option>
        </select>
      </div>

      {/* Line Chart */}
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={filteredData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="timestamp"
            tickFormatter={(timestamp) => {
              switch (timeInterval) {
                case 'minute':
                  return moment(timestamp).local().format('HH:mm');
                case 'hour':
                  return moment(timestamp).local().format('MMM D, HH:mm');
                case 'day':
                  return moment(timestamp).local().format('MMM D');
                case '7days':
                  return moment(timestamp).local().format('MMM D');
                case 'month':
                  return moment(timestamp).local().format('MMM YYYY');
                default:
                  return moment(timestamp).local().format('MMM D, HH:mm');
              }
            }}
          />
          <YAxis />
          <Tooltip
            labelFormatter={(timestamp) => moment(timestamp).local().format('MMM D, YYYY HH:mm')}
            formatter={(value, name) => [`${value} ${name}`, name]} // Display count and errors in tooltip
          />
          <Line
            type="monotone"
            dataKey="count"
            name="Total Logs"
            stroke="#8884d8"
            strokeWidth={2}
          />
          <Line
            type="monotone"
            dataKey="errors"
            name="Errors"
            stroke="#ff7300"
            strokeWidth={2}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Timeline;