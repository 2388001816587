import { useCallback, useState } from "react";
import {
  Card,
  CardContent,
  TextField,
  IconButton,
  Typography,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { Handle } from "reactflow";

import { TbEdit } from "react-icons/tb";
import { TbDatabase } from "react-icons/tb";
import { useNodeData } from "../NodeDataContext";
const theme = createTheme({
  palette: {
    primary: {
      main: "#8F27C1",
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow:
            "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
          borderRadius: "6px",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "16px",
          "&:last-child": {
            paddingBottom: "16px",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#E5E7EB",
            },
            "&:hover fieldset": {
              borderColor: "#E5E7EB",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#9333EA",
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#374151",
          "&.Mui-focused": {
            color: "#374151",
          },
        },
      },
    },
  },
});

export default function DatabaseConnection({ id }) {
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [titleText, setTitleText] = useState("Database Connection");
  const { nodeData, updateNodeData } = useNodeData();

  const handleEditStart = () => {
    setIsEditingTitle(true);
  };

  const handleTitleChange = (e) => {
    setTitleText(e.target.value);
  };

  const handleEditEnd = () => {
    setIsEditingTitle(false);
    updateNodeData(id, { ...nodeData[id], title: titleText });
  };

  const handleTitleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleEditEnd();
    }
  };

  const handleDatabaseNameChange = useCallback(
    (e) => {
      const newValue = e.target.value;
      updateNodeData(id, { ...nodeData[id], databaseName: newValue });
    },
    [id, nodeData, updateNodeData]
  );

  const handleApiKeyChange = useCallback(
    (e) => {
      const newValue = e.target.value;
      updateNodeData(id, { ...nodeData[id], apiKey: newValue });
    },
    [id, nodeData, updateNodeData]
  );

  return (
    <ThemeProvider theme={theme}>
      <div className="relative">
        {/* <Handle
          type="target"
          position="left"
          style={{ background: '#9333EA', width: '8px', height: '8px' }}
        />
         */}

        <Handle
          type="source"
          position="right"
          style={{ background: "#9333EA", width: "8px", height: "8px" }}
        />

        <Card sx={{ width: 300, border: "1px solid #E5E7EB" }}>
          <CardContent sx={{ padding: 0 }}>
            <div className="bg-[#F7F8FF80] mb-2.5">
              <div className="flex justify-between items-center py-1 px-2.5">
                <div className="flex items-center gap-2 font-bold">
                  <TbDatabase size={18} className="font-bold" />
                  {isEditingTitle ? (
                    <TextField
                      variant="standard"
                      value={titleText}
                      onChange={handleTitleChange}
                      onBlur={handleEditEnd}
                      onKeyDown={handleTitleKeyDown}
                      autoFocus
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          fontSize: "1rem",
                          fontWeight: 500,
                          padding: 0,
                          height: "24px",
                        },
                      }}
                    />
                  ) : (
                    <Typography
                      variant="body1"
                      onClick={handleEditStart}
                      className="font-medium cursor-pointer"
                    >
                      {titleText}
                    </Typography>
                  )}
                </div>
                <IconButton
                  size="medium"
                  className="p-2"
                  onClick={handleEditStart}
                >
                  <TbEdit size={20} className="font-bold" />
                </IconButton>
              </div>
            </div>

            <div className="px-4 pb-4 space-y-2">
              <div className="space-y-2">
                <Typography
                  variant="body2"
                  className="text-sm font-medium text-gray-700 mb-1"
                >
                  Database Name <span className="text-red-500">*</span>
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Enter Text here"
                  size="small"
                  value={nodeData[id]?.databaseName || ""}
                  onChange={handleDatabaseNameChange}
                  InputProps={{
                    style: { fontSize: "0.875rem" },
                  }}
                />
              </div>

              <div className="space-y-2">
                <Typography
                  variant="body2"
                  className="text-sm font-medium text-gray-700 mb-1"
                >
                  API Key <span className="text-red-500">*</span>
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="password"
                  placeholder="Enter API Key"
                  size="small"
                  value={nodeData[id]?.apiKey || ""}
                  onChange={handleApiKeyChange}
                  InputProps={{
                    style: { fontSize: "0.875rem" },
                  }}
                />
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </ThemeProvider>
  );
}
