import React, { createContext, useContext, useState } from "react";

const NodeDataContext = createContext();

export const NodeDataProvider = ({ children }) => {
  const [nodeData, setNodeData] = useState({});

  const updateNodeData = (nodeId, data) => {
    setNodeData((prev) => ({
      ...prev,
      [nodeId]: data,
    }));
  };

  return (
    <NodeDataContext.Provider value={{ nodeData, updateNodeData }}>
      {children}
    </NodeDataContext.Provider>
  );
};

export const useNodeData = () => useContext(NodeDataContext);
