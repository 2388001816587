import React from "react";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import Footer from "../Footer/Footer";
import Toolbar from "./Toolbar";
import PipelineUI from "./PipelineUI";

const AgenticUI = () => {
  return (
    <div className="flex w-full min-h-screen bg-[#fafafa]">
      {/* Sidebar Section */}
      <Sidebar className="flex-shrink-0 h-full fixed" />
      <div className="flex flex-col w-full">
        {/* Navbar Section */}
        <div className="ml-[90px]">
          <Navbar className="flex-shrink-0 fixed w-full" />
        </div>
        {/* Page content */}
        <div className="ml-[90px] px-2 pt-[84px] flex-grow overflow-scroll">
          <Toolbar />
          <PipelineUI />
        </div>
        {/* Footer Section */}
        <div className="ml-[90px]">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default AgenticUI;
