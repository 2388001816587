import { useState } from "react";
import {
  Card,
  CardContent,
  TextField,
  IconButton,
  Typography,
  ThemeProvider,
  createTheme,
} from "@mui/material";

import { TbEdit } from "react-icons/tb";
import { BsTextareaResize } from "react-icons/bs";
const theme = createTheme({
  palette: {
    primary: {
      main: "#8F27C1",
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow:
            "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
          borderRadius: "6px",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "16px",
          "&:last-child": {
            paddingBottom: "16px",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#E5E7EB",
            },
            "&:hover fieldset": {
              borderColor: "#E5E7EB",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#9333EA",
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#374151",
          "&.Mui-focused": {
            color: "#374151",
          },
        },
      },
    },
  },
});

export default function TextArea() {
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [titleText, setTitleText] = useState("Text Area");

  const handleEditStart = () => {
    setIsEditingTitle(true);
  };

  const handleTitleChange = (e) => {
    setTitleText(e.target.value);
  };

  const handleEditEnd = () => {
    setIsEditingTitle(false);
  };

  const handleTitleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleEditEnd();
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Card sx={{ width: 300, border: "1px solid #E5E7EB" }}>
        <CardContent sx={{ padding: 0 }}>
          <div className="bg-[#F7F8FF80] mb-2.5">
            <div className="flex justify-between items-center py-1 px-2.5">
              <div className="flex items-center gap-2 font-bold">
                <BsTextareaResize size={18} className="font-bold" />
                {isEditingTitle ? (
                  <TextField
                    variant="standard"
                    value={titleText}
                    onChange={handleTitleChange}
                    onBlur={handleEditEnd}
                    onKeyDown={handleTitleKeyDown}
                    autoFocus
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        fontSize: "1rem",
                        fontWeight: 500,
                        padding: 0,
                        height: "24px",
                      },
                    }}
                  />
                ) : (
                  <Typography
                    variant="body1"
                    onClick={handleEditStart}
                    className="font-medium cursor-pointer"
                  >
                    {titleText}
                  </Typography>
                )}
              </div>
              <IconButton
                size="medium"
                className="p-2"
                onClick={handleEditStart}
              >
                <TbEdit size={20} className="font-bold" />
              </IconButton>
            </div>
          </div>

          <div className="px-4 pb-4 space-y-2">
            <Typography
              variant="body2"
              className="text-sm font-medium text-gray-700 mb-1"
            >
              Enter Text <span className="text-red-500">*</span>
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              multiline
              rows={4}
              placeholder="Enter your text here"
              InputProps={{
                style: { fontSize: "0.875rem" },
              }}
            />
          </div>
        </CardContent>
      </Card>
    </ThemeProvider>
  );
}
