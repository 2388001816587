import { Typography } from "@mui/material";
import "./CustomSlider.css";
const CustomSlider = ({ label, value, setValue, min, max, step }) => {
  return (
    <div className="relative w-full mb-3">
      {/* Label and Value Section */}
      <div className="flex justify-between items-center mb-1">
        <Typography
          variant="body2"
          className="text-sm font-medium text-gray-700"
        >
          {label}
        </Typography>
        <div className="bg-white px-2 py-0.5 rounded-md border border-gray-200 text-xs min-w-[36px] text-center text-gray-600">
          {value}
        </div>
      </div>

      {/* Slider Section */}
      <div className="relative">
        <input
          type="range"
          min={min}
          max={max}
          step={step}
          value={value}
          style={{
            "--value": value,
            "--min": min,
            "--max": max,
          }}
          onChange={(e) => setValue(Number(e.target.value))}
          className="h-4 max-w-[265px] bg-gray-100 rounded-lg appearance-none cursor-pointer"
          // className="h-4 max-w-[265px] bg-gray-100 rounded-lg appearance-none cursor-pointer
          //   [&::-webkit-slider-thumb]:appearance-none
          //   [&::-webkit-slider-thumb]:w-3
          //   [&::-webkit-slider-thumb]:h-3
          //   [&::-webkit-slider-thumb]:rounded-full
          //   [&::-webkit-slider-thumb]:bg-purple-600
          //   [&::-webkit-slider-thumb]:cursor-pointer
          //   [&::-webkit-slider-thumb]:border-2
          //   [&::-webkit-slider-thumb]:border-white
          //   [&::-webkit-slider-thumb]:shadow-sm
          //   [&::-moz-range-thumb]:w-3
          //   [&::-moz-range-thumb]:h-3
          //   [&::-moz-range-thumb]:rounded-full
          //   [&::-moz-range-thumb]:bg-purple-600
          //   [&::-moz-range-thumb]:border-2
          //   [&::-moz-range-thumb]:border-white
          //   [&::-moz-range-thumb]:shadow-sm
          //   [&::-ms-thumb]:w-3
          //   [&::-ms-thumb]:h-3
          //   [&::-ms-thumb]:rounded-full
          //   [&::-ms-thumb]:bg-purple-600
          //   [&::-ms-thumb]:border-2
          //   [&::-ms-thumb]:border-white
          //   [&::-ms-thumb]:shadow-sm
          //   [&::-webkit-slider-runnable-track]:bg-purple-100
          //   [&::-webkit-slider-runnable-track]:rounded-lg
          //   [&::-moz-range-track]:bg-purple-100
          //   [&::-moz-range-track]:rounded-lg
          //   [&::-ms-track]:bg-purple-100
          //   [&::-ms-track]:rounded-lg"
        />

        {/* Min/Max labels */}
        <div className="flex justify-between">
          <span className="text-[10px] text-gray-400">{min}</span>
          <span className="text-[10px] text-gray-400">{max}</span>
        </div>
      </div>
    </div>
  );
};

export default CustomSlider;
