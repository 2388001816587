import React, { useState, useRef, useEffect, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Markdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { solarizedlight } from "react-syntax-highlighter/dist/esm/styles/prism";
import { CopyToClipboard } from "react-copy-to-clipboard";
import remarkGfm from "remark-gfm";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { PiCopySimpleThin } from "react-icons/pi";
import { IoCloseSharp } from "react-icons/io5";
import { MdKeyboardArrowRight } from "react-icons/md";
import uploadImg from "../../assets/aiModels/cloud-upload-regular-240.png";
import fileDefault from "../../assets/aiModels/file-blank-solid-240.png";
import fileCSS from "../../assets/aiModels/file-css-solid-240.png";
import filePdf from "../../assets/aiModels/file-pdf-solid-240.png";
import filePng from "../../assets/aiModels/file-png-solid-240.png";
import Loader from "../../assets/aiModels/QLoader.svg";
import Footer from "../Footer/Footer";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import "./modelsStyle.css";
import axiosInstance from "../../api/axios";

const MAX_FILE_SIZE = 20 * 1024 * 1024; // 20MB

function LinearProgressWithLabel({ value }) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" value={value} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

const GetRagUi = () => {
  const location = useLocation();
  const { model, modelIp } = location.state || {};

  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [messages, setMessages] = useState([]);
  const [typing, setTyping] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [copiedStates, setCopiedStates] = useState({});
  const messagesEndRef = useRef(null);

  const ImageConfig = {
    default: fileDefault,
    pdf: filePdf,
    png: filePng,
    css: fileCSS,
  };
  let modelUrl;
  if (modelIp.startsWith("https://")){
    modelUrl = `${modelIp}/api`
  }else{
    modelUrl = "/resources/rag";
  }
  const url = new URL(modelIp);

  // Extract the public IP (hostname)
  const publicIp = url.hostname; // e.g., "198.145.127.245"
  
  // Extract the path segments
  const pathSegments = url.pathname.split("/").filter(Boolean); // Remove empty strings
  const email = pathSegments[0]; // First segment is the email
  const modelName = pathSegments[2]; // Third segment is the model name



  const formatName = (fname) => {
    return fname.length > 12 ? `${fname.substring(0, 16)}...` : fname;
  };

  const formatSize = (size) => {
    if (size < 1024) return `${size}B`;
    if (size < 1024 * 1024) return `${Math.ceil(size / 1024)}KB`;
    return `${Math.ceil(size / (1024 * 1024))}MB`;
  };

  const onFileDrop = (e) => {
    const newFiles = Array.from(e.target.files);
    const validFiles = newFiles.filter((file) => file.size <= MAX_FILE_SIZE);
    if (validFiles.length !== newFiles.length) {
      toast.error("Some files exceed the 20MB limit");
    }
    setFileList((prev) => [...prev, ...validFiles]);
  };

  const fileRemove = (file) => {
    setFileList((prev) => prev.filter((f) => f !== file));
    toast.success(`File removed: ${file.name}`);
  };

  const uploadFiles = async () => {
    if (!fileList.length) return;
    const formData = new FormData();
    formData.append("operation", "upload");
    formData.append("email", email);
    formData.append("model_name", modelName );
    formData.append("public_ip", publicIp);

    fileList.forEach((file) => formData.append("files", file));
    setUploading(true);
    try {
      const response = await axiosInstance.post(modelUrl, formData, {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setProgress(percentCompleted);
        },
      });
      if (response.status === 200) {
        toast.success("Files uploaded successfully");
      }
    } catch (error) {
      toast.error("File upload failed");
    } finally {
      setUploading(false);
    }
  };

  const fetchChatHistory = useCallback(async () => {
    try{
      let response;

      if (modelIp.startsWith("https://")) {
        response = await axiosInstance.get(`/${email}/models/${modelName}/run/chat`
        );
      } else {
        const formData = new FormData();
        formData.append("operation", "fetch_chat_history");
        formData.append("email", email);
        formData.append("model_name", modelName );
        formData.append("public_ip", publicIp);
        response = await axiosInstance.post(`/resources/rag`,formData);

    }
    
    
    if (response.status === 200) {
        const chatHistory = response.data.chat_history;
        const formattedMessages = chatHistory.map((msg, index) => ({
          id: index,
          content: msg.content,
          own: msg.role === "user",
        }));
        setMessages(formattedMessages);
      }
    } catch (error) {
      console.error("Error fetching chat history:", error);
      toast.error("Chat history not found. Please start a conversation to view data");
    }
  }, [modelIp, modelUrl, email, modelName, publicIp, setMessages]);

  const sendMessage = useCallback(async (message) => {
    if (!message.trim()) return;
    setMessages((prev) => [...prev, { id: Date.now(), content: message, own: true }]);
    setTyping(true);
    try {
      const formData = new FormData();
      formData.append("operation", "query");
      formData.append("question", message);
      formData.append("email", email);
      formData.append("model_name", modelName );
      formData.append("public_ip", publicIp);
      const response = await axiosInstance.post(modelUrl,formData);
      setMessages((prev) => [
        ...prev,
        { id: Date.now(), content: response.data.message, own: false },
      ]);
    } catch (error) {
      toast.error("Failed to send message");
    } finally {
      setTyping(false);
    }
  }, []);

  const clearChatHistory = useCallback(async () => {
    try {
      const formData = new FormData();
      formData.append("operation", "clear");
      formData.append("email", email);
      formData.append("model_name", modelName );
      formData.append("public_ip", publicIp);
      await axiosInstance.post(modelUrl,formData);
      setMessages([]);
      toast.success("Chat history cleared");
    } catch (error) {
      toast.error("Failed to clear chat history");
    }
  }, []);

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      sendMessage(newMessage);
      setNewMessage("");
    }
  };

  const handleCopy = (messageId, codeIndex) => {
    const key = `${messageId}-${codeIndex}`;
    setCopiedStates((prev) => ({ ...prev, [key]: true }));
    setTimeout(() => {
      setCopiedStates((prev) => ({ ...prev, [key]: false }));
    }, 2000);
  };

  useEffect(() => {
    fetchChatHistory();
  }, [fetchChatHistory]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <div className="flex w-full min-h-screen">
      <Sidebar className="flex-shrink-0 h-full fixed" />
      <div className="flex flex-col w-full">
        <div className="ml-[90px]">
          <Navbar className="flex-shrink-0 fixed w-full" />
        </div>

        <div className="ml-[90px] px-5 lg:px-10 pt-24 InitialStyle h-full overflow-auto">
          <div className="flex items-center py-6">
            <Link to="/modelStudio" state={{ openTab: 2 }}>
              <Typography variant="h6" component="h2" className="text-xl font-bold text-heading-black">
                Model Studio
              </Typography>
            </Link>
            <MdKeyboardArrowRight className="text-2xl" />
            <Typography variant="h6" component="h2" className="text-xl font-bold text-heading-black">
              RAG UI
            </Typography>
          </div>

          <div className="chat__box pb-2 flex flex-col sm:flex-row justify-center gap-y-5 sm:gap-x-5 mb-[30px]">
            <div className="w-full sm:w-[300px] p-4 border shadow-md rounded-[8px] flex flex-col justify-between bg-white">
              <div className="drop-file-input bg-white">
                <div className="drop-file-input__label">
                  <img src={uploadImg} alt="" />
                  <p>Click or Drop your files here</p>
                </div>
                <input type="file" multiple onChange={onFileDrop} />
              </div>

              {fileList.length > 0 && (
                <div className="drop-file-preview overflow-auto">
                  {uploading ? (
                    <button className="loader-button bg-white w-[130px] h-[42px] mb-2.5">
                      <svg className="animate-spin h-5 w-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.96 7.96 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647zm5.195 2.647A8.034 8.034 0 0112 20c4.418 0 8-3.582 8-8h-4c0 2.761-2.239 5-5 5a4.998 4.998 0 01-4.805-3.653z"></path>
                      </svg>
                      {"wait... "}
                      {progress}%
                    </button>
                  ) : (
                    <button className="classic-button bg-white w-[130px] h-[42px] mb-2.5" onClick={uploadFiles}>
                      Upload
                    </button>
                  )}
                  {fileList.map((item, index) => (
                    <div key={index} className="drop-file-preview__item0">
                      <div className="drop-file-preview__item">
                        <img src={ImageConfig[item.type.split("/")[1]] || ImageConfig["default"]} alt="" />
                        <div className="drop-file-preview__item__info">
                          <p>{formatName(item.name)}</p>
                          <p>{formatSize(item.size)}</p>
                        </div>
                        <span className="drop-file-preview__item__del" onClick={() => fileRemove(item)}>
                          x
                        </span>
                      </div>
                      <LinearProgressWithLabel value={progress} />
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="sm:w-[910px] p-4 border shadow-md rounded-[8px] relative grow flex flex-col w-full bg-white">
              <div className="flex justify-end mb-4">
                <button onClick={clearChatHistory} className="bg-red-500 text-white px-4 py-2 rounded-md">
                  Clear Chat
                </button>
              </div>
              <div className="flex-1 mb-4 overflow-auto">
                {messages.map((message) => (
                  <div key={message.id} className={`message ${message.own ? "own bg-blue-100" : "bg-gray-100"} p-2 rounded-md mb-2`}>
                    <Markdown
                      remarkPlugins={[remarkGfm]}
                      components={{
                        code({ node, inline, className, children, ...props }) {
                          const match = /language-(\w+)/.exec(className || "");
                          const codeIndex = node?.position?.start?.offset;
                          return match ? (
                            <div style={{ position: "relative" }}>
                              <SyntaxHighlighter style={solarizedlight} language={match[1]} PreTag="div" {...props}>
                                {String(children).replace(/\n$/, "")}
                              </SyntaxHighlighter>
                              <CopyToClipboard text={String(children).replace(/\n$/, "")} onCopy={() => handleCopy(message.id, codeIndex)}>
                                <button className="copy-to-clipboard__button">
                                  {copiedStates[`${message.id}-${codeIndex}`] ? "Copied!" : <PiCopySimpleThin />}
                                </button>
                              </CopyToClipboard>
                            </div>
                          ) : (
                            <code className={`${className}`} {...props}>
                              <span className="rounded bg-gray-200">{children}</span>
                            </code>
                          );
                        },
                      }}
                    >
                      {message.content}
                    </Markdown>
                  </div>
                ))}
                {typing && (
                  <div className="message bg-blue-100 p-2 rounded-md w-max flex items-center space-x-1">
                    <div className="flex items-center space-x-1">
                      <div>Typing</div>
                      <div className="animate-bounce [animation-delay:-0.3s]">.</div>
                      <div className="animate-bounce [animation-delay:-0.15s]">.</div>
                      <div className="animate-bounce">.</div>
                    </div>
                  </div>
                )}
                <div ref={messagesEndRef} />
              </div>

              <div className="sticky bottom-0 p-2">
                <hr />
                <div className="flex items-center px-3 py-2 rounded-lg bg-white dark:bg-gray-700">
                  <textarea
                    rows="1"
                    className="block mx-4 p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Your message..."
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    onKeyDown={handleKeyPress}
                  ></textarea>
                  <button
                    type="submit"
                    className="inline-flex justify-center p-2 text-gen-color rounded-full cursor-pointer hover:bg-blue-100 dark:text-purple-500 dark:hover:bg-gray-600"
                    onClick={() => {
                      sendMessage(newMessage);
                      setNewMessage("");
                    }}
                  >
                    <svg className="w-5 h-5 rotate-90" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                      <path d="m17.914 18.594-8-18a1 1 0 0 0-1.828 0l-8 18a1 1 0 0 0 1.157 1.376L8 18.281V9a1 1 0 0 1 2 0v9.281l6.758 1.689a1 1 0 0 0 1.156-1.376Z" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ml-[90px]">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default GetRagUi;