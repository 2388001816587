import React, { useEffect, useRef, useState } from "react";
import { CiSquarePlus } from "react-icons/ci";
import { BsSearch } from "react-icons/bs";
import AddHuggingModal from "./AddHuggingModal";
import { FaRegTrashAlt } from "react-icons/fa";
import { FaRegPlayCircle } from "react-icons/fa";
import { IoCodeSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import axiosInstance from "../../../api/axios";
import { HiDotsVertical } from "react-icons/hi";
import { IoTimeOutline } from "react-icons/io5";
import { InfoOutlined, Refresh, CheckCircle, Warning, Error, PlayCircle, StopCircle } from "@mui/icons-material";
import {
  Modal,
  Button,
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";


const StatusInfoModal = ({ status, open, onClose,selectedModel }) => {
  const statusInfo = {
    "Deployment In-Progress": {
      description: " Model is currently being deployed. This process may take a few minutes.",
      action: "Click the Check Status button from the actions menu to view the live deployment progress and stay updated on the current status.Thank you for your patience!",
      icon: <Refresh className="animate-spin" />,
      color: "text-yellow-500",
      // actionButton: (
      //   <Button
      //   onClick={() => {
      //     setSelectedModelName(selectedModel);
      //     // setHuggingFaceToken(model.hugging_face_token || model.feature.hf_token);
      //     setShowStartDeployModal(true);
      //   }}
      //     variant="contained"
      //     color="primary"
      //   >
      //     Start Deployment
      //   </Button>
      // ),
    },
    "Deployment Stopped": {
      description: "The deployment has been stopped. You can restart it if needed.",
      action: "Click the Start button from the actions menu to resume the deployment process.",
      icon: <StopCircle />,
      color: "text-red-500",
    },
    "Tuning Completed": {
      description: "The model tuning process has been successfully completed.",
      action: "You can now deploy the model or view detailed logs.",
      icon: <CheckCircle />,
      color: "text-green-500",
    },
    "Deployment Ready": {
      description: "The model has been successfully deployed on your GPU.",
      action: "You can now perform RAG (Retrieval-Augmented Generation) to start using it.",
      icon: <CheckCircle />,
      color: "text-green-500"
    },
    "Tuning Failed": {
      description: "The model tuning process has failed.",
      action: "Please check the logs for more details or retry the tuning process.",
      icon: <Error />,
      color: "text-red-500",
    },
    "Model Downloading Failed": {
      description: "The model could not be downloaded onto the deployment GPU.",
      action: "Please try selecting a higher GPU or verify the Hugging Face token you provided.",
      icon: <Error />,
      color: "text-red-500"
    },
    "Model Loading": {
      description: "The model is currently loading onto your GPU. This process may take a few minutes.",
      action: "Please wait while the model is being prepared.",
      icon: <Refresh className="animate-spin" />,
      color: "text-yellow-500",
    }
    // Add more statuses as needed
  };

  const info = statusInfo[status] || {
    description: "Status information not available.",
    action: "No specific action required.",
    icon: <InfoOutlined />,
    color: "text-gray-500",
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-6 rounded-lg shadow-lg w-96">
        <Typography variant="h6" className="flex items-center space-x-2">
          {info.icon}
          <span className={info.color}>{status}</span>
        </Typography>
        <Typography variant="body1" className="mt-4">
          {info.description}
        </Typography>
        <Typography variant="body2" className="mt-2 text-gray-600">
          {info.action}
        </Typography>
        <div className="mt-6 flex justify-end space-x-2">
          <Button onClick={onClose} variant="outlined">
            Close
          </Button>
          {info.actionButton}
        </div>
      </Box>
    </Modal>
  );
};

const HuggingFaceModels = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showActionsForModel, setShowActionsForModel] = useState(null);
  const actionRef = useRef(null);
  const [models, setModels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modelsLoading, setModelsLoading] = useState(false);
  const [modelsError, setModelsError] = useState(null);
  const navigate = useNavigate();
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [showEditAutoStopModal, setShowEditAutoStopModal] = useState(false);
  const [deleteTunedModel, setDeleteTunedModel] = useState(null);
  const [showStartDeployModal, setShowStartDeployModal] = useState(false);
  const [selectedModel, setSelectedModel] = useState(null);
  const [huggingFaceToken, setHuggingFaceToken] = useState("");
  const [autoStop, setAutoStop] = useState(60);
  const [showToken, setShowToken] = useState(false);
  const [statusModalOpen, setStatusModalOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");

  const handleStatusClick = (status) => {
    setSelectedStatus(status);
    setStatusModalOpen(true);
  };

  const handleAddHuggingModel = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  const LoaderComponent = () => <ClipLoader color={"#000"} loading={true} size={24} />;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (actionRef.current && !actionRef.current.contains(event.target)) {
        setShowActionsForModel(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleRefreshToken = async () => {
    try {
      await axiosInstance.post("/newuser/api/refresh");
      return true;
    } catch (error) {
      console.log("Refresh token error:", error);
      return false;
    }
  };

  const viewDetails = (model) => {
    // Logic to view model details
    navigate("/container-logs", { state: { model } });
  };

  const getCodeModel = (modelId, modelIp, modelType) => {
    navigate("/getCode", { state: { modelIp, modelId, modelType } });
  };

  const getUiModel = (model, modelIp) => {
    navigate("/getUi", { state: { model, modelIp } });
  };

  const getRagUiModel = (model, modelIp) => {
    navigate("/getRagUi", { state: { model, modelIp } });
  };
  

  const handleStartDeploy = async () => {
    if (!autoStop || isNaN(autoStop) || autoStop <= 0) {
      toast.error("Please enter a valid auto-stop duration in hours.");
      return;
    }
  
    setLoading(true);
  
    // Set a timeout for 30 seconds
    const timeoutDuration = 30000; // 30 seconds in milliseconds
    let timeoutHandle;
  
    const timeoutPromise = new Promise((_, reject) => {
      timeoutHandle = setTimeout(() => {
        reject(new Error("Request timed out"));
      }, timeoutDuration);
    });
  
    try {
      const response = await Promise.race([
        axiosInstance.post("/resources/start_hugging_models", {
          model_name: selectedModel?.model_name,
          hugging_face_token: huggingFaceToken,
          auto_stop: autoStop,
        }),
        timeoutPromise,
      ]);
  
      clearTimeout(timeoutHandle); // Clear the timeout if the request completes in time
  
      toast.success("Deployment started successfully!");
      setShowStartDeployModal(false);
      loadModels();
    } catch (error) {
      if (error.message === "Request timed out") {
        // toast.error("Request took too long. Please try again.");
        setShowStartDeployModal(false)
        // setShowStartDeployModal(false); // Close the modal
        loadModels(); // Refresh the models list
        navigate("/modelStudio?tab=huggingFaceModels");
      } else {
        console.error("Error starting deployment:", error);
        toast.error("Failed to start deployment.");
      }
    } finally {
      setLoading(false);
    }
  };

  // const handleStartDeploy = async () => {
  //   if (!autoStop || isNaN(autoStop) || autoStop <= 0) {
  //     toast.error("Please enter a valid auto-stop duration in hours.");
  //     return;
  //   }

  //   setLoading(true);
  //   try {
  //     const response = await axiosInstance.post("/resources/start_hugging_models", {
  //       model_name: selectedModel?.model_name,
  //       hugging_face_token: huggingFaceToken,
  //       auto_stop: autoStop, 
  //     });
  //     toast.success("Deployment started successfully!");
  //     setShowStartDeployModal(false);
  //     loadModels();
  //     // window.location.reload();
  //   } catch (error) {
  //     console.error("Error starting deployment:", error);
  //     toast.error("Failed to start deployment.");
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleStopDeploy = async (model) => {
    setLoading(true);
    try {
      const response = await axiosInstance.post("/resources/stop_models", {
        plan_name: model?.model_name,
      });
      toast.success("Deployment stopped successfully!");
      // window.location.reload();
      loadModels();
    } catch (error) {
      console.error("Error stopping deployment:", error);
      toast.error("Failed to stop deployment.");
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteModel = (modelId, model) => {
    setDeleteTunedModel(model);
    setShowDeleteConfirmationModal(true);
  };

  const confirmDeleteModel = () => {
    if (deleteTunedModel) {
      setShowDeleteConfirmationModal(false);
      deleteModel(deleteTunedModel);
    }
  };

  const deleteModel = async (model) => {
    setLoading(true);
    // const requestData = {
    //   plan_name: model?.model_name,
    //   // type: model?.type,
    // };

    try {
      const response = await axiosInstance.post("/resources/delete_models", {
        plan_name: model?.model_name,
      });
      toast.success(response?.data?.message);
      loadModels();
    } catch (error) {
      console.error("Error deleting model:", error);
      if (error.response?.status === 401) {
        const refresh = await handleRefreshToken();
        if (refresh) deleteModel(model);
      } else {
        toast.error(error.response?.data?.message || "Something went wrong");
      }
    } finally {
      setLoading(false);
    }
  };

  // Add the new handler
  const handleEditAutoStop = async () => {
    if (!autoStop || isNaN(autoStop) || autoStop <= 0) {
      toast.error("Please enter a valid auto-stop duration in hours.");
      return;
    }

    setLoading(true);
    try {
      console.log(autoStop,"aaaaaaaaaaaaaaaaaaaa")
      const response = await axiosInstance.post("/resources/edit_stop_models", {
        plan_name: selectedModel?.model_name,
        auto_stop:autoStop,
        // auto_stop: parseInt(autoStop, 60),
      });
      console.log(response,"aaaaaaaaaaaaaaaaaaaa")
      toast.success("Auto-stop updated successfully!");
      setShowEditAutoStopModal(false);
      loadModels(); // Refresh the models list
    } catch (error) {
      console.error("Error updating auto-stop:", error);
      toast.error("Failed to update auto-stop.");
    } finally {
      setLoading(false);
    }
  };


  const loadModels = async () => {
    setModelsLoading(true);
    try {
      const response = await axiosInstance.get("/nocode/get-models?type=huggingface%20model");
      const modelsData = response?.data?.data;
      setModels(response.data.success && modelsData?.length > 0 ? modelsData : []);
    } catch (error) {
      if (error.response?.status === 401) {
        const refresh = await handleRefreshToken();
        if (refresh) loadModels();
      } else {
        setModelsError(error.message || "Failed to fetch models");
      }
    } finally {
      setModelsLoading(false);
    }
  };

  useEffect(() => {
    loadModels();
    const interval = setInterval(loadModels, 30000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="min-h-[600px] w-full bg-white px-8 pb-8 shadow-md">
      <div className="flex flex-col sm:flex-row sm:justify-between items-center py-4 space-y-4 sm:space-y-0">
        <div
          className="flex items-center justify-center w-full sm:w-auto p-2 border border-gray-600 rounded-md font-medium cursor-pointer"
          onClick={handleAddHuggingModel}
        >
          <CiSquarePlus className="text-xl" />
          <span className="ml-1 text-sm">Add Model</span>
        </div>
        <div className="flex flex-col sm:flex-row items-center sm:space-x-4 w-full sm:w-auto space-y-4 sm:space-y-0">
          <div className="relative w-full sm:w-48">
            <BsSearch className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-500" />
            <input
              type="text"
              placeholder="Search"
              className="border p-2 pl-8 rounded-md text-sm w-full"
            />
          </div>
        </div>
      </div>

      <div className="border-2 rounded-[8px] mt-2">
        <div className="min-h-[400px] max-h-[545px] rounded-[8px] bg-white overflow-auto">
          <table className="w-full border-collapse">
            <thead className="sticky top-0 bg-[#EFF0F8] border-b rounded-t-[8px] z-20">
              <tr>
                <th className="px-10 py-4 text-start text-[15px] font-[600]">Model Name</th>
                <th className="px-10 py-4 text-start text-[15px] font-[600]">Model Id</th>
                <th className="px-10 py-4 text-start text-[15px] font-[600]">GPU</th>
                <th className="px-10 py-4 text-center text-[15px] font-[600]">GPU Count</th>
                <th className="py-4 px-10 text-sm font-semibold text-center">Status</th>
                <th className="px-10 py-4 text-start text-[15px] font-[600]">Action</th>
              </tr>
            </thead>
            <tbody className="max-h-[500px] overflow-y-auto">
              {models.length > 0 ? (
                models.map((model) => (
                  <tr key={model._id} className="w-full">
                    <td className="py-4 px-10 text-sm font-normal">{model.model_name}</td>
                    <td className="py-4 px-10 text-sm font-normal">{model.model_id || "-"}</td>
                    {/* <td className="py-4 px-10 text-sm font-normal">{model.feature.gpu_name || "N/A"}</td> */}
                    <td className="py-4 px-10 text-sm font-normal">
                      {model.provider === "AWS" ? "NVIDIA A10G" : "NVIDIA A100"}
                    </td>
                    <td className="py-4 px-10 text-sm font-normal text-center">{model.feature.number_of_gpu || 0}</td>
                    <td className="text-sm font-medium text-center">
                      <span
                        className={`ml-4 rounded-lg py-1 px-2 inline-flex items-center whitespace-nowrap font-semibold ${
                          model.status === "Tuning In-progress"
                            ? "bg-yellow-100 text-yellow-500 hover:bg-yellow-200 cursor-pointer"
                            : model.status === "Container Created"
                            ? "bg-sky-100 text-sky-500 hover:bg-sky-200 cursor-pointer"
                            : model.status === "Model Loading"
                            ? "bg-purple-100 text-purple-500 hover:bg-purple-200 cursor-pointer"
                            : model.status === "Tuning Failed"
                            ? "bg-red-100 text-red-500 hover:bg-red-200 cursor-pointer"
                            : model.status === "Model Downloading Failed"
                            ? "bg-red-100 text-red-500 hover:bg-red-200 cursor-pointer"
                            : model.status === "Tuning Completed"
                            ? "bg-green-100 text-green-500 hover:bg-green-200 cursor-pointer"
                            : model.status === "Tuning Initialized"
                            ? "bg-pink-100 text-pink-500 hover:bg-pink-200 cursor-pointer"
                            : model.status === "Deployment Ready"
                            ? "bg-blue-100 text-blue-500 hover:bg-blue-200 cursor-pointer"
                            : model.status === "Deployment Initialized"
                            ? "bg-indigo-100 text-indigo-600 hover:bg-indigo-200 cursor-pointer"
                            : model.status === "Deployment Stopped"
                            ? "bg-red-100 text-red-500 hover:bg-red-200 cursor-pointer"
                            : model.status === "Deployment In-Progress"
                            ? "bg-orange-100 text-orange-500 hover:bg-orange-200 cursor-pointer"
                            : "text-black py-4 px-10 text-sm font-normal"
                        }`}
                        onClick={() => handleStatusClick(model.status)}
                      >
                        {model.status}
                        </span>
                    </td>
                    <td className="relative">
                      <HiDotsVertical
                        className="mt-0.5 ml-12 cursor-pointer"
                        onClick={() =>
                          setShowActionsForModel(showActionsForModel === model._id ? null : model._id)
                        }
                      />
                      {showActionsForModel === model._id && (
                        <div
                          ref={actionRef}
                          className="absolute right-0 top-0 z-10 bg-white shadow-lg rounded-md p-2 mt-2 w-48 ring-1 ring-black ring-opacity-5"
                        >
                          {/* // Add the new button in the actions dropdown */}
                          {model.status === "Deployment Ready" || model.status === "Model Loading" ? (
                            <button
                              className="flex items-center w-full text-base px-4 py-2 text-black"
                              onClick={() => {
                                setSelectedModel(model);
                                setAutoStop(model.requested_duration_left|| 60); // Default to 60 if remaining_time_left is not available
                                setShowEditAutoStopModal(true);
                              }}
                            >
                              <IoTimeOutline className="mr-2 h-5 w-5" /> Edit Auto-Stop
                            </button>
                          ) : null}
                          {model.status === "Deployment Ready" ? (
                            <button
                              className={`flex items-center w-full text-base px-4 py-2 ${
                                loading ? "text-gray-400 cursor-not-allowed" : "text-black"
                              }`}
                              onClick={() => !loading && handleStopDeploy(model)}
                              disabled={loading}
                            >
                              {loading ? <LoaderComponent /> : <FaRegPlayCircle className="mr-2 h-5 w-5" />}
                              {loading ? "Stopping..." : "Stop"}
                            </button>
                          ) : model.status === "Deployment Stopped" ? (
                            <button
                              className={`flex items-center w-full text-base px-4 py-2 ${
                                loading ? "text-gray-400 cursor-not-allowed" : "text-black"
                              }`}
                              onClick={() => {
                                setSelectedModel(model);
                                setHuggingFaceToken(model.hugging_face_token || model.feature.hf_token);
                                setShowStartDeployModal(true);
                              }}
                              disabled={loading}
                            >
                              {loading ? <LoaderComponent /> : <FaRegPlayCircle className="mr-2 h-5 w-5" />}
                              {loading ? "Starting..." : "Start / Stop"}
                            </button>
                          ) : (
                            <button
                              className="flex items-center w-full text-base px-4 py-2 text-gray-400 cursor-not-allowed"
                              disabled
                            >
                              <FaRegPlayCircle className="mr-2 h-5 w-5" /> Start / Stop
                            </button>
                          )}
                        {model.provider === "AWS" && (
                          <button
                            className={`flex items-center w-full text-base px-4 py-2 ${
                              model.status === "Deployment Ready" ? "text-black" : "text-gray-400 cursor-not-allowed"
                            }`}
                            disabled={model.status !== "Deployment Ready"}
                            onClick={() => getCodeModel(model._id, model.feature.service_ip, model.type)}
                          >
                            <IoCodeSharp className="mr-2 h-5 w-5" /> Get Code
                          </button>
                        )}

                          <button
                            className={`flex items-center w-full text-base px-4 py-2 ${
                              model.status === "Deployment Ready" ? "text-black" : "text-gray-400 cursor-not-allowed"
                            }`}
                            disabled={model.status !== "Deployment Ready"}
                            onClick={() => getUiModel(model, model.feature.service_ip)}
                          >
                            <IoCodeSharp className="mr-2 h-5 w-5" /> Interactive UI
                          </button>

                          <button
                            className={`flex items-center w-full text-base px-4 py-2 ${
                              model.status === "Deployment Ready" ? "text-black" : "text-gray-400 cursor-not-allowed"
                            }`}
                            disabled={model.status !== "Deployment Ready"}
                            onClick={() => getRagUiModel(model, model.feature.service_ip)}
                          >
                            <IoCodeSharp className="mr-2 h-5 w-5" /> Rag UI
                          </button>

                          {model.provider === "AWS" && (
                            <button
                              className={`flex items-center w-full text-base px-4 py-2 ${
                                model.status === "Deployment Ready" || model.status === "Model Loading"
                                  ? "text-black"
                                  : "text-gray-400 cursor-not-allowed"
                              }`}
                              disabled={!(model.status === "Deployment Ready" || model.status === "Model Loading")}
                              onClick={() => viewDetails(model)}
                            >
                              <IoCodeSharp className="mr-2 h-5 w-5" /> View Logs
                            </button>
                          )}


                          <button
                            className={`flex items-center w-full text-base px-4 py-2 ${
                              model.status === "Tuning Failed" ||
                              model.status === "Tuning Completed" ||
                              model.status === "Deployment Stopped" ||
                              model.status === "Model Loading" ||
                              model.status === "Deployment Failed" ||
                              model.status === "Deployment Ready" ||
                              model.status === "Model Downloading Failed"
                                ? "text-black"
                                : "text-gray-400 cursor-not-allowed"
                            }`}
                            disabled={
                              !(
                                model.status === "Tuning Failed" ||
                                model.status === "Tuning Completed" ||
                                model.status === "Deployment Stopped" ||
                                model.status === "Model Loading" ||
                                model.status === "Deployment Failed" ||
                                model.status === "Deployment Ready" ||
                                model.status === "Model Downloading Failed"
                              )
                            }
                            onClick={() => handleDeleteModel(model._id, model)}
                          >
                            <FaRegTrashAlt className="mr-2 h-5 w-5" /> Delete
                          </button>
                        </div>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6} className="text-center text-lg font-normal py-4">
                    No models found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {isOpen && <AddHuggingModal isOpen={isOpen} setIsOpen={setIsOpen} />}

      {showStartDeployModal && (
        <div className="fixed z-40 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="mt-3 text-center sm:mt-5">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">Start Deployment</h3>
                </div>
                <div className="mt-5">
                  <TextField
                    label="Hugging Face Token"
                    name="huggingFaceToken"
                    value={huggingFaceToken}
                    onChange={(e) => setHuggingFaceToken(e.target.value)}
                    className="border p-2 rounded-md text-sm w-full"
                    type={showToken ? "text" : "password"} 
                    autoComplete="new-password" // Prevent autofill
                    helperText="💡 Please ensure that you use a Hugging Face token with full access to download models. The token must have the appropriate permissions to authenticate and interact with Hugging Face's model repository. If your token does not have sufficient access, you may face issues when trying to download models or use them for inference. Make sure your token is linked to an account that has the necessary access rights to the models you intend to use."
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowToken(!showToken)}
                            edge="end"
                          >
                            {showToken ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    
                    }}
                  />

                  
                  
                </div>
                <div className="mt-5">
                  <TextField
                    label="Auto Stop (in hours)"
                    name="auto_stop"
                    type="number"
                    value={autoStop}
                    onChange={(e) => setAutoStop(e.target.value)}
                    className="border p-2 rounded-md text-sm w-full"
                    inputProps={{ min: 1 }}
                    helperText="💡 Set the number of hours after which the model will automatically stop. This helps manage costs and resources efficiently."

                  />
                </div>
                <div className="mt-5 sm:mt-6 flex justify-center">
                  <button
                    type="button"
                    className="cancel-button"
                    onClick={() => setShowStartDeployModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="general-button ml-5"
                    onClick={handleStartDeploy}
                    disabled={loading}
                  >
                    {loading ? <LoaderComponent /> : "Start Deployment"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showDeleteConfirmationModal && (
         <div className="fixed z-40 inset-0 overflow-y-auto">
         <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
           <div
             className="fixed inset-0 transition-opacity"
             aria-hidden="true"
           >
             <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
           </div>
           <span
             className="hidden sm:inline-block sm:align-middle sm:h-screen"
             aria-hidden="true"
           >
             &#8203;
           </span>
           <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
             <div>
               <div className="mt-3 text-center sm:mt-5">
                 <h3 className="text-lg leading-6 font-medium text-gray-900">
                   Are you sure you want to delete this
                   model?
                 </h3>
               </div>
               <div className="mt-5 sm:mt-6 flex justify-center">
                 <button
                   type="button"
                   className="cancel-button"
                   onClick={() => confirmDeleteModel()}
                 >
                   Delete
                 </button>
                 <button
                   type="button"
                   className="general-button ml-5"
                   onClick={() =>
                     setShowDeleteConfirmationModal(false)
                   }
                 >
                   Cancel
                 </button>
               </div>
             </div>
           </div>
         </div>
       </div>
      )}
      
      {/* // Add the new modal */}
      {showEditAutoStopModal && (
        <div className="fixed z-40 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="mt-3 text-center sm:mt-5">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">Edit Auto-Stop</h3>
                </div>
                <div className="mt-5">
                  <Typography variant="body1" className="mb-4">
                    Approx {selectedModel?.requested_duration_left || 60} hours left before the model stops.
                    {/* Approx {selectedModel} hours left before the model stops. */}
                  </Typography>
                  <br></br>
                  <TextField
                    label="Auto Stop (in hours)"
                    name="auto_stop"
                    type="number"
                    value={autoStop}
                    onChange={(e) => setAutoStop(e.target.value)}
                    className="border p-2 rounded-md text-sm w-full"
                    inputProps={{ min: 1 }}
                    helperText="💡 Set the number of hours after which the model will automatically stop."
                  />
                </div>
                <div className="mt-5 sm:mt-6 flex justify-center">
                  <button
                    type="button"
                    className="cancel-button"
                    onClick={() => setShowEditAutoStopModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="general-button ml-5"
                    onClick={handleEditAutoStop}
                    disabled={loading}
                  >
                    {loading ? <LoaderComponent /> : "Update Auto-Stop"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <StatusInfoModal
        status={selectedStatus}
        open={statusModalOpen}
        onClose={() => setStatusModalOpen(false)}
        onStartDeploy={handleStartDeploy}
      />

    </div>
  )
}
export default HuggingFaceModels;

  

