import { useCallback, useState } from "react";
import {
  Card,
  CardContent,
  TextField,
  IconButton,
  Typography,
  ThemeProvider,
  createTheme,
} from "@mui/material";

import { TbEdit } from "react-icons/tb";
import { PiDatabaseBold } from "react-icons/pi";
import { Handle } from "reactflow";
import { useNodeData } from "../NodeDataContext";
const theme = createTheme({
  palette: {
    primary: {
      main: "#8F27C1",
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow:
            "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
          borderRadius: "6px",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "16px",
          "&:last-child": {
            paddingBottom: "16px",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#E5E7EB",
            },
            "&:hover fieldset": {
              borderColor: "#E5E7EB",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#9333EA",
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#374151",
          "&.Mui-focused": {
            color: "#374151",
          },
        },
      },
    },
  },
});

export default function SqlQueryGenerator({ id }) {
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const { nodeData, updateNodeData } = useNodeData();

  const titleText = nodeData[id]?.title || "SQL Query Executor";
  const inputQuery = nodeData[id]?.inputQuery || "";

  const handleEditStart = () => {
    setIsEditingTitle(true);
  };

  const handleTitleChange = (e) => {
    updateNodeData(id, { ...nodeData[id], title: e.target.value });
  };

  const handleEditEnd = () => {
    setIsEditingTitle(false);
  };

  const handleTitleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleEditEnd();
    }
  };

  const handleInputQueryChange = useCallback(
    (e) => {
      updateNodeData(id, { ...nodeData[id], inputQuery: e.target.value });
    },
    [id, nodeData, updateNodeData]
  );

  return (
    <div className="relative">
      {/* Left Handle */}
      <Handle
        type="target"
        position="left"
        style={{ background: "#9333EA", width: "8px", height: "8px" }}
      />

      {/* Right Handle */}
      <Handle
        type="source"
        position="right"
        style={{ background: "#9333EA", width: "8px", height: "8px" }}
      />

      <ThemeProvider theme={theme}>
        <Card sx={{ width: 300, border: "1px solid #E5E7EB" }}>
          <CardContent sx={{ padding: 0 }}>
            <div className="bg-[#F7F8FF80] mb-2.5">
              <div className="flex justify-between items-center py-1 px-2.5">
                <div className="flex items-center gap-2 font-bold">
                  <PiDatabaseBold size={18} className="font-bold" />
                  {isEditingTitle ? (
                    <TextField
                      variant="standard"
                      value={titleText}
                      onChange={handleTitleChange}
                      onBlur={handleEditEnd}
                      onKeyDown={handleTitleKeyDown}
                      autoFocus
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          fontSize: "1rem",
                          fontWeight: 500,
                          padding: 0,
                          height: "24px",
                        },
                      }}
                    />
                  ) : (
                    <Typography
                      variant="body1"
                      onClick={handleEditStart}
                      className="font-medium cursor-pointer"
                    >
                      {titleText}
                    </Typography>
                  )}
                </div>
                <IconButton
                  size="medium"
                  className="p-2"
                  onClick={handleEditStart}
                >
                  <TbEdit size={20} className="font-bold" />
                </IconButton>
              </div>
            </div>

            <div className="px-4 pb-4 space-y-2">
              <div>
                <Typography
                  variant="body2"
                  className="text-sm font-medium text-gray-700 mb-1"
                >
                  Input Query <span className="text-red-500">*</span>
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  multiline
                  rows={3}
                  placeholder="Enter your query here"
                  value={inputQuery}
                  onChange={handleInputQueryChange}
                  InputProps={{
                    style: { fontSize: "0.875rem" },
                  }}
                />
              </div>

              {/* <div>
                <Typography
                  variant="body2"
                  className="text-sm font-medium text-gray-700 mb-1"
                >
                  Output Result <span className="text-red-500">*</span>
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  multiline
                  rows={3}
                  placeholder="Generated SQL query will appear here"
                  InputProps={{
                    style: { fontSize: "0.875rem" },
                  }}
                />
              </div> */}
            </div>
          </CardContent>
        </Card>
      </ThemeProvider>
    </div>
  );
}
