import React, { useEffect, useRef, useState } from "react";
import loader from "../../assets/ui_img/loader.svg";
import { HiDotsVertical } from "react-icons/hi";
import { FaRegPlayCircle, FaRegStopCircle } from "react-icons/fa";
import { IoCodeSharp } from "react-icons/io5";
import { TbExternalLinkOff } from "react-icons/tb";
import { FiEye } from "react-icons/fi";
import axiosInstance from "../../api/axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { FaRegTrashAlt } from "react-icons/fa";
import DeployModal from "../DashboardActions/DeployModal";
import { IoTimeOutline } from "react-icons/io5";
import { RefreshOutlined } from "@mui/icons-material"; 
// import { Tooltip, IconButton, Modal, Box, Typography, Button } from "@mui/material";
import { InfoOutlined, Refresh, CheckCircle, Warning, Error, PlayCircle, StopCircle } from "@mui/icons-material";
// import AddCreditModal from "../AddCreditModal";
import {
  Modal, 
  Box, 
 
  Button,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";

const StatusInfoModal = ({ status, open, onClose,selectedModel }) => {
  const statusInfo = {
    "Deployment In-Progress": {
      description: " Model is currently being deployed. This process may take a few minutes.",
      action: "Click the Check Status button from the actions menu to view the live deployment progress and stay updated on the current status.Thank you for your patience!",
      icon: <Refresh className="animate-spin" />,
      color: "text-yellow-500",
      // actionButton: (
      //   <Button
      //   onClick={() => {
      //     setSelectedModelName(selectedModel);
      //     // setHuggingFaceToken(model.hugging_face_token || model.feature.hf_token);
      //     setShowStartDeployModal(true);
      //   }}
      //     variant="contained"
      //     color="primary"
      //   >
      //     Start Deployment
      //   </Button>
      // ),
    },
    "Deployment Stopped": {
      description: "The deployment has been stopped. You can restart it if needed.",
      action: "Click the Start button from the actions menu to resume the deployment process.",
      icon: <StopCircle />,
      color: "text-red-500",
    },
    "Tuning Completed": {
      description: "The model tuning process has been successfully completed.",
      action: "You can now deploy the model or view detailed logs.",
      icon: <CheckCircle />,
      color: "text-green-500",
    },
    "Tuning Failed": {
      description: "The model tuning process has failed.",
      action: "Please check the logs for more details or retry the tuning process.",
      icon: <Error />,
      color: "text-red-500",
    },
    // Add more statuses as needed
  };

  const info = statusInfo[status] || {
    description: "Status information not available.",
    action: "No specific action required.",
    icon: <InfoOutlined />,
    color: "text-gray-500",
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-6 rounded-lg shadow-lg w-96">
        <Typography variant="h6" className="flex items-center space-x-2">
          {info.icon}
          <span className={info.color}>{status}</span>
        </Typography>
        <Typography variant="body1" className="mt-4">
          {info.description}
        </Typography>
        <Typography variant="body2" className="mt-2 text-gray-600">
          {info.action}
        </Typography>
        <div className="mt-6 flex justify-end space-x-2">
          <Button onClick={onClose} variant="outlined">
            Close
          </Button>
          {info.actionButton}
        </div>
      </Box>
    </Modal>
  );
};

const TunedModelTab = ({
  openTab,
  onTabChange,
  convertToLocalTime,
  handleRefreshToken,
}) => {
  const [loading, setLoading] = useState(false);

  const [models, setModels] = useState([]);
  const [modelsLoading, setModelsLoading] = useState(false);
  const [modelsError, setModelsError] = useState(null);
  const [showActionsForModel, setShowActionsForModel] = useState(null);
  // const [selectedModel, setSelectedModel] = useState(null);
  const [selectedModel, setSelectedModelName] = useState(null);
  const [selectedmodelId, setSelectedModelId] = useState(null);
  const [isDeployModalOpen, setIsDeployModalOpen] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const actionRef = useRef(null);
  const navigate = useNavigate();
  const [showStartDeployModal, setShowStartDeployModal] = useState(false);
  const [autoStop, setAutoStop] = useState(60);
  const [deleteTunedModel, setDeleteTunedModel] = useState(null);
  const [openAction, setOpenAction] = useState(false);
  const [isBottomOverflowing, setIsBottomOverflowing] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [showEditAutoStopModal, setShowEditAutoStopModal] = useState(false);
  const LoaderComponent = () => (
    <ClipLoader color={"#000"} loading={true} size={24} />
  );
  const [checkingStatus, setCheckingStatus] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");

  const [statusModalOpen, setStatusModalOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");

  const handleStatusClick = (status) => {
    setSelectedStatus(status);
    setStatusModalOpen(true);
  };


  

  // Function to load models
  const loadModels = async () => {
    // setModelsLoading(true);
    try {
      const response = await axiosInstance.get("/nocode/get-models");
      const modelsData = response?.data?.data;
      if (response.data.success && modelsData && modelsData.length > 0) {
        setModels(modelsData);
        console.log("Model details: " + models);
      } else {
        // Show nothing when array is empty
        setModels([]); // Clear models when there's no data
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const refresh = await handleRefreshToken();
        if (refresh) {
          loadModels();
        }
      } else {
        setModelsError(error.message || "Failed to fetch models");
      }
    } finally {
      setModelsLoading(false);
    }
  };
  const viewDetails = (model) => {
    // Ensure modelType is properly defined
    const modelType = model?.type;  // Assuming model has a 'type' field

    if (modelType === "tuned model") {
        navigate("/view-detail", { state: { model } });
    } else {
        navigate("/container-logs", { state: { model } });
    }
};

  const deployModel = (modelId, modelName) => {
    // Logic to deploy the model
    // navigate("/deploy", { state: { modelId } });
    setSelectedModelName(modelName);
    // setSelectedPrice(price);
    setSelectedModelId(modelId);
    setIsDeployModalOpen(true);
  };

  const getCodeModel = (modelId, modelIp, modelType) => {
    navigate("/getCode", { state: { modelIp, modelId, modelType } });
  };
  const getUiModel = (model, modelIp) => {
    navigate("/getUiT", { state: { model, modelIp } });
  };

  // Add the new handler
  const handleEditAutoStop = async () => {
    if (!autoStop || isNaN(autoStop) || autoStop <= 0) {
      toast.error("Please enter a valid auto-stop duration in hours.");
      return;
    }

    setLoading(true);
    try {
      console.log(autoStop,"aaaaaaaaaaaaaaaaaaaa")
      const response = await axiosInstance.post("/resources/edit_stop_models", {
        plan_name: selectedModel?.model_name,
        auto_stop:autoStop,
        // auto_stop: parseInt(autoStop, 60),
      });
      console.log(response,"aaaaaaaaaaaaaaaaaaaa")
      toast.success("Auto-stop updated successfully!");
      setShowEditAutoStopModal(false);
      loadModels(); // Refresh the models list
    } catch (error) {
      console.error("Error updating auto-stop:", error);
      toast.error("Failed to update auto-stop.");
    } finally {
      setLoading(false);
    }
  };

  // Function to open the confirmation modal
  const handleDeleteModel = (modelId, model) => {
    console.log("Model ID to delete:", modelId); // Debugging
    setDeleteTunedModel(model); // Store the ID of the model to delete
    setShowDeleteConfirmationModal(true); // Show the modal
  };

  // Function to delete the model and close the modal
  const confirmDeleteModel = () => {
    console.log("Confirm delete for model ID:", deleteTunedModel); // Debugging
    if (deleteTunedModel) {
      setShowDeleteConfirmationModal(false); // Close the modal
      deleteModel(deleteTunedModel); // Call delete function with the stored model ID
    }
  };

  // Function to delete the model using the API
  const deleteModel = async (model) => {
    console.log("Deleting model ID:", model); // Debugging

    setLoading(true);

    // Prepare request payload
    // const requestData = {
    //   plan_name: model?.model_name,
    //   type: model?.type,
    // };

    // Determine API endpoint based on model type
    // let apiEndpoint = "";
    // if (model?.type === "tuned model") {
    //   apiEndpoint = "/nocode/delete-tuned-model";
    // } else if (model?.type === "Deployed model") {
    //   apiEndpoint = "/tune/delete_deploy";
    // } else {
    //   console.error("Invalid model type:", model?.type);
    //   toast("Invalid model type");
    //   setLoading(false);
    //   return; // Exit if model type is not valid
    // }

    // const requestData = {
    //   plan_name: model?.model_name,
    // };

    try {
      const response = await axiosInstance.post("resources/delete_models", {
        plan_name: model?.model_name,
      });
      console.log("Delete response:", response); // Debugging
      toast(response?.data?.message);
      loadModels(); // Reload models after deletion
    } catch (error) {
      console.error("Error deleting model:", error); // Debugging
      if (error.response && error.response.status === 401) {
        const refresh = await handleRefreshToken();
        if (refresh) {
          deleteModel(model); // Retry after refreshing token
        }
      } else {
        console.error("Error deleting model:", error);
        toast(error.response?.data?.message || "Something went wrong");
      }
    } finally {
      setLoading(false);
    }
  };

  const checkStatus = async (model) => {
    setCheckingStatus(true);
    setStatusMessage("Checking live status of the instance...");
    const uniqueKey = `deploymentIntervalId_${model?.model_name}`;
  
    // If an existing interval is present, clear it first
    const existingIntervalId = localStorage.getItem(uniqueKey);
    if (existingIntervalId) {
      clearInterval(parseInt(existingIntervalId)); // Stop the previous interval
    }
  
    // Start a new polling interval
    const intervalId = setInterval(async () => {
      try {
        const statusResponse = await axiosInstance.get(
          `/resources/track-model-deployment-status?plan_name=${model?.model_name}`
        );
        const status = statusResponse.data.status;
  
        // Stop polling if the instance is running
        if (status === "Complete") {
          clearInterval(intervalId);
          setCheckingStatus(false);
          setStatusMessage("Instance is now Available To Use!");
          loadModels(); // Refresh the instances list
          toast.success(`${model?.model_name} is now Available To Use!`);
          localStorage.removeItem(uniqueKey);
        } else {
          setStatusMessage("Resources are being reallocated. Please wait...");
        }
      } catch (error) {
        clearInterval(intervalId);
        setCheckingStatus(false);
        setStatusMessage("Error fetching deployment status. Please try again.");
        console.error("Error fetching deployment status:", error);
        toast.error("Error fetching deployment status.");
      }
    }, 60000); // Poll every 60 seconds
  
    // Store the new interval ID, replacing the old one if necessary
    localStorage.setItem(uniqueKey, intervalId);
  };
  

  // Function to handle the Start Deployment API
  const handleStartDeploy = async () => {
    if (!autoStop || isNaN(autoStop) || autoStop <= 0) {
          toast.error("Please enter a valid auto-stop duration in hours.");
          return;
        }
    setLoading(true);
    try {
      const response = await axiosInstance.post("/resources/start_tunning_models", {
        model_name: selectedModel?.model_name,
        auto_stop: autoStop,
      });
      if (response  && response.data) {
        toast(response.data.message);
        // Close the modal immediately
        setShowStartDeployModal(false);
        loadModels();
      
        // setIsLoading(false);
        // setIsDeploying(false);
        // setIsDeployModalOpen(false);
        // if (response.data.message === 'Insufficient Credit To Launch Instance') {
        //   setIsCreditOpen(true);
        // }
        const uniqueKey = `deploymentIntervalId_${response.data.plan_name}`;

        const intervalId = setInterval(async () => {
          try {
            const statusResponse = await axiosInstance.get(
              `/resources/track-model-deployment-status?plan_name=${response.data.model_name}`
            );
            // const statusResponse = await axiosInstance.get(
            //   `http://34.229.44.220:5000/resources/track-deployment-status?plan_name=${response.data.plan_name}`
            // );
  
            const status = statusResponse.data.status;
  
            // Step 3: Update UI and stop polling if status is 'Complete'
            if (status === 'Complete') {
              loadModels();
              clearInterval(intervalId); // Stop polling once the status is 'Complete'
              toast(`${response.data.model_name} is now Available To Use!`);
              localStorage.removeItem(uniqueKey); // Remove the interval from localStorage once it's completed
            }
          } catch (error) {
            console.error("Error fetching status:", error);
            localStorage.removeItem(uniqueKey);
            toast("Error fetching deployment status. Please try again later.");
          }
        }, 90000); // Poll every 5 seconds

        localStorage.setItem(uniqueKey, intervalId);

        // Step 5: Navigate to the next page after a short delay
        setTimeout(() => {
          setLoading(false);
          navigate("/myInstance", { state: { openTab: 2 } });
        }, 5000);
  

      }
    }catch (error) {
        console.error("Error starting deployment:", error);
        toast(error.response?.data?.message || "Failed to start instance");

        // Step 6: Handle token refresh if necessary
        if (error.response && error.response.status === 401) {
          const refresh = await handleRefreshToken();
          if (refresh) {
            handleStartDeploy();
          }
        } 
      } finally {
        // Optional: Stop loading state after a short delay
          
          setTimeout(() => {
            setLoading(false);
            // setIsLoading(false);
            // setIsDeploying(false);
            // setIsDeployModalOpen(false);
            navigate("/myInstance", { state: { openTab: 2 } });
          }, 5000);
        }
      };
  //     toast.success("Deployment started successfully!");
  //     setShowStartDeployModal(false);
  //     loadModels();
  //     console.log("Start deployment success:", response.data);
  //     // Handle success (e.g., update UI or model status)
  //   } catch (error) {
  //     console.error("Error starting deployment:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // Function to handle the Stop Deployment API
  const handleStopDeploy = async (model) => {
    setLoading(true);
    try {
      const response = await axiosInstance.post("/resources/stop_models", {
        plan_name: model?.model_name,
      });
      // window.location.reload();
      loadModels();
      console.log("Stop deployment success:", response.data);
      // Handle success (e.g., update UI or model status)
    } catch (error) {
      console.error("Error stopping deployment:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Call all functions once when the component mounts
    loadModels();
    // loadInstance();
    // loadSynthHistory();

    // Set interval to call them every 30 seconds
    const interval = setInterval(() => {
      loadModels();
      // loadInstance();
      //   loadSynthHistory();
    }, 30000);

    // Cleanup interval when component unmounts
    return () => clearInterval(interval);
  }, []); // Empty dependency array ensures this runs once when mounted

  // Load models when Tuned Model tab is active
  useEffect(() => {
    if (openTab === 2 && models.length === 0) {
      loadModels();
    }
  }, [openTab]);
  const handleAction = (modelId) => {
    setShowActionsForModel((prevModelId) =>
      prevModelId === modelId ? null : modelId
    );
  };

  // Close the action if clicked outside the div
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (actionRef.current && !actionRef.current.contains(event.target)) {
        setShowActionsForModel(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (openAction) {
      const actionElement = actionRef.current;
      if (actionElement) {
        const { bottom } = actionElement.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        // Check if the dropdown's bottom would overflow the viewport
        if (bottom > windowHeight) {
          setIsBottomOverflowing(true); // Overflow detected
        } else {
          setIsBottomOverflowing(false); // No overflow
        }
      }
    }
  }, [openAction]);

  const handleOptionClick = (option) => {
    if (option === "viewDetail") {
      navigate("/view-detail");
    } else if (option === "deploy") {
      navigate("/deploy");
    } else if (option === "delete") {
      navigate("/delete");
    }
  };

  function capitalizeWords(str) {
    if (!str) return ""; // Check if the string is undefined, null, or empty
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  const totalPages = Math.ceil(models.length / pageSize);
  const currentModel = models.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
    setCurrentPage(1);
  };

  return (
    <>
      <div
        className={`${openTab === 2 ? "block" : "hidden"} overflow-x-auto`}
        id="link2"
      >
        {modelsLoading ? (
          <div className="flex justify-center items-center">
            <img src={loader} alt="Loading..." className="spinner" />
            <p className="ml-2">Loading models...</p>
          </div>
        ) : modelsError ? (
          <div className="text-red-500">Error: {modelsError}</div>
        ) : (
          <table className="w-full min-w-fit">
            <thead className="bg-table-header-bg-color">
              <tr>
                <th className="py-4 px-10 text-sm font-semibold text-left">
                  Model Name
                </th>
                <th className="py-4 px-10 text-sm font-semibold text-left">
                  GPU
                </th>
                <th className="py-4 px-10 text-sm font-semibold text-left">
                  Created On
                </th>
                <th className="py-4 px-10 text-sm font-semibold text-left">
                  Base Model
                </th>
                <th className="py-4 px-10 text-sm font-semibold text-left">
                  Type
                </th>
                <th className=" py-4 px-10 text-sm font-semibold text-center">
                  Status
                </th>
                <th className="py-4 px-10 text-sm font-semibold text-left">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {currentModel.map((model, i) => (
                <tr
                  key={model._id}
                  className={`${
                    i % 2 === 0 ? "bg-white" : "bg-light-gray-1"
                  } w-full`}
                >
                  <td className="py-4 px-10 text-sm font-normal">
                    {model.model_name}
                  </td>
                  <td className="py-4 px-10 text-sm font-normal">
                    {/* {model.feature.gpu_name} */}
                    {"NVIDIA A10G"}
                  </td>
                  <td className="py-4 px-10 text-sm font-normal">
                    {convertToLocalTime(model.create_date_time)}
                  </td>
                  <td className="py-4 px-10 text-sm font-normal">
                    {model.model_id}
                  </td>
                  <td className="text-sm font-medium text-center">
                    <span
                      className={`rounded-lg py-1 px-2 inline-flex items-center whitespace-nowrap ${model.type}`}
                    >
                      {capitalizeWords(model.type)}
                    </span>
                  </td>

                  <td className="text-sm font-medium text-center">
                    <span
                      className={`ml-4 rounded-lg py-1 px-2 inline-flex items-center whitespace-nowrap font-semibold ${
                        model.status === "Tuning In-progress"
                          ? "bg-yellow-100 text-yellow-500 hover:bg-yellow-200 cursor-pointer"
                          : model.status === "Container Created"
                          ? "bg-sky-100 text-sky-500 hover:bg-sky-200 cursor-pointer"
                          : model.status === "Model Loading"
                          ? "bg-purple-100 text-purple-500 hover:bg-purple-200 cursor-pointer"
                          : model.status === "Tuning Failed"
                          ? "bg-red-100 text-red-500 hover:bg-red-200 cursor-pointer"
                          : model.status === "Tuning Completed"
                          ? "bg-green-100 text-green-500 hover:bg-green-200 cursor-pointer"
                          : model.status === "Tuning Initialized"
                          ? "bg-pink-100 text-pink-500 hover:bg-pink-200 cursor-pointer"
                          : model.status === "Deployment Ready"
                          ? "bg-blue-100 text-blue-500 hover:bg-blue-200 cursor-pointer"
                          : model.status === "Deployment Initialized"
                          ? "bg-indigo-100 text-indigo-600 hover:bg-indigo-200 cursor-pointer"
                          : model.status === "Deployment Stopped"
                          ? "bg-red-100 text-red-500 hover:bg-red-200 cursor-pointer"
                          : model.status === "Deployment In-Progress"
                          ? "bg-orange-100 text-orange-500 hover:bg-orange-200 cursor-pointer"
                          : "text-black py-4 px-10 text-sm font-normal"
                      }`}
                      onClick={() => handleStatusClick(model.status)}
                    >
                      {model.status}
                    </span>
                  </td>
                  <td>

                    


                    

                    <HiDotsVertical
                      className="mt-0.5 ml-12 cursor-pointer"
                      onClick={() => handleAction(model._id)}
                    />

                    
                    

                    {/* Dropdown buttons based on the model status */}
                    {showActionsForModel === model._id && (
                      <div
                        ref={actionRef}
                        className="absolute right-0 lg:right-auto z-10 bg-white shadow-lg rounded-md p-2 mt-2 w-48  ring-1 ring-black ring-opacity-5"
                      >
                        {/* View Details Button */}
                        <button
                          className={`flex items-center w-full text-base px-4 py-2 ${
                            [
                              "Tuning Initialized",
                              "Model Loading",
                              "Tuning In-progress",
                              "Deployment Ready"
                            ].includes(model.status)
                              ? "text-black" // Active state
                              : "text-gray-400 cursor-not-allowed pointer-events-none" // Inactive state
                          }`}
                          disabled={
                            ![
                              "Tuning Initialized",
                              "Model Loading",
                              "Tuning In-progress",
                              "Deployment Ready"
                            ].includes(model.status)
                          }
                          onClick={() => viewDetails(model)}
                        >
                          <FiEye className="mr-2 h-5 w-5" /> View Logs
                        </button>
                        {/* Add the "Check Status" Button */}
                          {(model.status === "Deployment In-Progress" || model.status === "Tuning In-progress" || model.status === "Container Created") && (
                            <button
                              className="flex items-center w-full text-base px-4 py-2 text-black"
                              onClick={() => checkStatus(model)}
                              disabled={checkingStatus}
                            >
                              {checkingStatus ? (
                                <ClipLoader size={20} color="#000" />
                              ) : (
                                <RefreshOutlined className="mr-2 h-5 w-5 animate-spin-slow" />
                              )}
                              {checkingStatus ? "Checking Status..." : "Check Status"}
                            </button>
                          )}
                          {statusMessage && (
                            <div className="mt-2 text-sm text-gray-600">
                              {statusMessage}
                            </div>
                          )}

                        {/* // Add the new button in the actions dropdown */}
                                                  {model.status === "Deployment Ready" || model.status === "Model Loading" ? (
                                                    <button
                                                      className="flex items-center w-full text-base px-4 py-2 text-black"
                                                      onClick={() => {
                                                        setSelectedModelName(model);
                                                        setAutoStop(model.requested_duration_left|| 60); // Default to 60 if remaining_time_left is not available
                                                        setShowEditAutoStopModal(true);
                                                      }}
                                                    >
                                                      <IoTimeOutline className="mr-2 h-5 w-5" /> Edit Auto-Stop
                                                    </button>
                                                  ) : null}
                        {/* Deploy Button */}
                        <button
                          className={`flex items-center w-full text-base px-4 py-2 ${
                            model.status === "Tuning Completed"
                              ? "text-black"
                              : "text-gray-400 cursor-not-allowed"
                          }`}
                          disabled={model.status !== "Tuning Completed"}
                          onClick={() =>
                            deployModel(model._id, model.model_name)
                          }
                        >
                          <TbExternalLinkOff className="mr-2 h-5 w-5" /> Deploy
                        </button>
                        {/* Get Code Button */}
                        <button
                          className={`flex items-center w-full text-base px-4 py-2 ${
                            model.status === "Deployment Ready"
                              ? "text-black"
                              : "text-gray-400 cursor-not-allowed"
                          }`}
                          disabled={model.status !== "Deployment Ready"}
                          onClick={() =>
                            getCodeModel(
                              model._id,
                              model.feature.service_ip,
                              model.type
                            )
                          }
                        >
                          <IoCodeSharp className="mr-2 h-5 w-5" /> Get Code
                        </button>

                        <button
                          className={`flex items-center w-full text-base px-4 py-2 ${
                            model.status === "Deployment Ready"
                              ? "text-black"
                              : "text-gray-400 cursor-not-allowed"
                          }`}
                          disabled={model.status !== "Deployment Ready"}
                          onClick={() =>
                            getUiModel(model, model.feature.service_ip)
                          }
                        >
                          <IoCodeSharp className="mr-2 h-5 w-5" /> Interactive
                          UI
                        </button>

                        {/* Start/Stop Button */}
                        {model.status === "Deployment Ready" ? (
                          <button
                            className={`flex items-center w-full text-base px-4 py-2 ${
                              loading
                                ? "text-gray-400 cursor-not-allowed"
                                : "text-black"
                            }`}
                            onClick={() => !loading && handleStopDeploy(model)}
                            disabled={loading}
                          >
                            {loading ? (
                              <LoaderComponent />
                            ) : (
                              <FaRegStopCircle className="mr-2 h-5 w-5" /> // Use Stop icon for "Deployment Ready" status
                            )}
                            {loading ? "Stopping..." : "Stop"}
                          </button>
                        ) : model.status === "Deployment Stopped" ? (
                          <button
                            className={`flex items-center w-full text-base px-4 py-2 ${
                              loading
                                ? "text-gray-400 cursor-not-allowed"
                                : "text-black"
                            }`}
                            // onClick={() => !loading && handleStartDeploy(model)}
                            onClick={() => {
                              setSelectedModelName(model);
                              // setHuggingFaceToken(model.hugging_face_token || model.feature.hf_token);
                              setShowStartDeployModal(true);
                            }}
                            disabled={loading}
                          >
                            {loading ? (
                              <LoaderComponent />
                            ) : (
                              <FaRegPlayCircle className="mr-2 h-5 w-5" /> // Use Play icon for "Deployment Stopped" status
                            )}
                            {loading ? "Starting..." : "Start"}
                          </button>
                        ) : (
                          <button
                            className="flex items-center w-full text-base px-4 py-2 text-gray-400 cursor-not-allowed"
                            disabled
                          >
                            <FaRegPlayCircle className="mr-2 h-5 w-5" />
                            Start / Stop
                          </button>
                        )}

                        {/* Delete Button */}
                        <button
                          className={`flex items-center w-full text-base px-4 py-2 ${
                            model.status === "Tuning Failed" ||
                            model.status === "Tuning Completed" ||
                            model.status === "Deployment Stopped" ||
                            model.status === "Deployment Ready"
                              ? "text-black"
                              : "text-gray-400 cursor-not-allowed"
                          }`}
                          disabled={
                            model.status !== "Tuning Failed" &&
                            model.status !== "Tuning Completed" &&
                            model.status !== "Deployment Stopped" &&
                            model.status !== "Deployment Ready"
                          }
                          onClick={() => handleDeleteModel(model._id, model)}
                        >
                          <FaRegTrashAlt className="mr-2 h-5 w-5" /> Delete
                        </button>
                      </div>
                    )}
                    {/* Confirmation Modal for Model Deletion */}
                    {showDeleteConfirmationModal &&
                      deleteTunedModel._id === model._id && (
                        <div className="fixed z-10 inset-0 overflow-y-auto">
                          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                            <div
                              className="fixed inset-0 transition-opacity"
                              aria-hidden="true"
                            >
                              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                            </div>
                            <span
                              className="hidden sm:inline-block sm:align-middle sm:h-screen"
                              aria-hidden="true"
                            >
                              &#8203;
                            </span>
                            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                              <div>
                                <div className="mt-3 text-center sm:mt-5">
                                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                                    Are you sure you want to delete this model?
                                  </h3>
                                </div>
                                <div className="mt-5 sm:mt-6 flex justify-center">
                                  <button
                                    type="button"
                                    className="cancel-button"
                                    onClick={() => confirmDeleteModel()} // Call confirmDeleteModel on click
                                  >
                                    Delete
                                  </button>
                                  <button
                                    type="button"
                                    className="general-button ml-5"
                                    onClick={() =>
                                      setShowDeleteConfirmationModal(false)
                                    } // Close modal
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {openAction && (
          <div
            ref={actionRef}
            className={`fixed z-10 mt-40 w-48 rounded-lg shadow-xl bg-white ring-1 ring-black ring-opacity-5 ${
              isBottomOverflowing ? "bottom-2" : "top-auto"
            } right-2`}
          >
            <div className="flex flex-col p-2">
              <button
                className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                onClick={() => handleOptionClick("viewDetail")}
              >
                <FiEye className="mr-2" /> View Detail
              </button>
              <button
                className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                onClick={() => handleOptionClick("deploy")}
                disabled
              >
                <TbExternalLinkOff className="mr-2 h-5 w-5 text-gray-400" />{" "}
                Deploy
              </button>
              <button
                className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                // onClick={() =>
                //   handleOptionClick("delete")
                // }
              >
                <FaRegTrashAlt className="mr-2 h-5 w-5" /> Delete
              </button>
            </div>
          </div>
        )}
        {/* Pagination */}
        <div className=" bg-white flex justify-end items-center border-t border-gray-100 p-2 space-x-3">
          <div className="flex items-center space-x-2">
            <span className="text-sm font-medium">Rows Per Page:</span>
            <select
              className="border rounded px-2 py-1 text-sm bg-rgba(248, 249, 253, 0.5)"
              value={pageSize}
              onChange={handlePageSizeChange}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
            </select>
          </div>
          <div className="flex items-center space-x-4">
            <span className="text-sm">
              {currentModel.length > 0
                ? `${(currentPage - 1) * pageSize + 1} - ${Math.min(
                    currentPage * pageSize,
                    models.length
                  )} of ${models.length}`
                : "0"}
            </span>
            <button
              className={`px-3 py-1 border rounded ${
                currentPage === 1 && "opacity-50 cursor-not-allowed"
              }`}
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              &lt;
            </button>
            <span className="text-sm">{currentPage}</span>
            <button
              className={`px-3 py-1 border rounded ${
                currentPage === totalPages && "opacity-50 cursor-not-allowed"
              }`}
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              &gt;
            </button>
          </div>
        </div>
        {/* Deploy Modal */}
        {isDeployModalOpen && (
          <DeployModal
            isDeployModalOpen={isDeployModalOpen}
            setIsDeployModalOpen={setIsDeployModalOpen}
            // price={selectedPrice}
            modelName={selectedModel}
            modelId={selectedmodelId}
          />
        )}
      </div>
    
    {showStartDeployModal && (
      <div className="fixed z-40 inset-0 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
            <div>
              <div className="mt-3 text-center sm:mt-5">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Start Deployment</h3>
              </div>
              <div className="mt-5">
                {/* <TextField
                  label="Hugging Face Token"
                  name="huggingFaceToken"
                  value={huggingFaceToken}
                  onChange={(e) => setHuggingFaceToken(e.target.value)}
                  className="border p-2 rounded-md text-sm w-full"
                  type={showToken ? "text" : "password"} 
                  autoComplete="new-password" // Prevent autofill
                  helperText="💡 Please ensure that you use a Hugging Face token with full access to download models. The token must have the appropriate permissions to authenticate and interact with Hugging Face's model repository. If your token does not have sufficient access, you may face issues when trying to download models or use them for inference. Make sure your token is linked to an account that has the necessary access rights to the models you intend to use."
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowToken(!showToken)}
                          edge="end"
                        >
                          {showToken ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  
                  }}
                /> */}

                
                
              </div>
              <div className="mt-5">
                <TextField
                  label="Auto Stop (in hours)"
                  name="auto_stop"
                  type="number"
                  value={autoStop}
                  onChange={(e) => setAutoStop(e.target.value)}
                  className="border p-2 rounded-md text-sm w-full"
                  inputProps={{ min: 1 }}
                  helperText="💡 Set the number of hours after which the model will automatically stop. This helps manage costs and resources efficiently."

                />
              </div>
              <div className="mt-5 sm:mt-6 flex justify-center">
                <button
                  type="button"
                  className="cancel-button"
                  onClick={() => setShowStartDeployModal(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="general-button ml-5"
                  onClick={handleStartDeploy}
                  disabled={loading}
                >
                  {loading ? <LoaderComponent /> : "Start Deployment"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )}
    {showEditAutoStopModal && (
            <div className="fixed z-40 inset-0 overflow-y-auto">
              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                  <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                  &#8203;
                </span>
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                  <div>
                    <div className="mt-3 text-center sm:mt-5">
                      <h3 className="text-lg leading-6 font-medium text-gray-900">Edit Auto-Stop</h3>
                    </div>
                    <div className="mt-5">
                      <Typography variant="body1" className="mb-4">
                        Approx {selectedModel?.requested_duration_left || 60} hours left before the model stops.
                        {/* Approx {selectedModel} hours left before the model stops. */}
                      </Typography>
                      <br></br>
                      <TextField
                        label="Auto Stop (in hours)"
                        name="auto_stop"
                        type="number"
                        value={autoStop}
                        onChange={(e) => setAutoStop(e.target.value)}
                        className="border p-2 rounded-md text-sm w-full"
                        inputProps={{ min: 1 }}
                        helperText="💡 Set the number of hours after which the model will automatically stop."
                      />
                    </div>
                    <div className="mt-5 sm:mt-6 flex justify-center">
                      <button
                        type="button"
                        className="cancel-button"
                        onClick={() => setShowEditAutoStopModal(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="general-button ml-5"
                        onClick={handleEditAutoStop}
                        disabled={loading}
                      >
                        {loading ? <LoaderComponent /> : "Update Auto-Stop"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <StatusInfoModal
        status={selectedStatus}
        open={statusModalOpen}
        onClose={() => setStatusModalOpen(false)}
        onStartDeploy={handleStartDeploy}
      />
    </>
  );
};

export default TunedModelTab;
