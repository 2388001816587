import { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  TextField,
  IconButton,
  Typography,
  ThemeProvider,
  createTheme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { Handle } from "reactflow";

import { TbEdit } from "react-icons/tb";
import { PiUploadSimpleBold } from "react-icons/pi";
import { useNodeData } from "../NodeDataContext";
const theme = createTheme({
  palette: {
    primary: {
      main: "#8F27C1",
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow:
            "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
          borderRadius: "6px",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "16px",
          "&:last-child": {
            paddingBottom: "16px",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#E5E7EB",
            },
            "&:hover fieldset": {
              borderColor: "#E5E7EB",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#9333EA",
            },
          },
        },
      },
    },
  },
});

export default function Output({ id, data }) {
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [titleText, setTitleText] = useState("Output");
  const [outputType, setOutputType] = useState("text");
  const [outputData, setOutputData] = useState(null);
  const { nodeData, updateNodeData } = useNodeData();

  const handleEditStart = () => {
    setIsEditingTitle(true);
  };

  const handleTitleChange = (e) => {
    setTitleText(e.target.value);
  };

  const handleEditEnd = () => {
    setIsEditingTitle(false);
    updateNodeData(id, { ...nodeData[id], title: titleText });
  };

  const handleTitleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleEditEnd();
    }
  };
  useEffect(() => {
    if (data?.inputData) {
      if (typeof data.inputData === "object") {
        setOutputType("dataframe");
        setOutputData(data.inputData);
      } else {
        setOutputType("text");
        setOutputData(data.inputData);
      }
      updateNodeData(id, {
        ...nodeData[id],
        outputType,
        outputData: data.inputData,
      });
    }
  }, [data, id, nodeData, updateNodeData, outputType]);

  // useEffect(() => {
  //   // This effect would handle incoming data from the handle
  //   // For now, we'll simulate it with a timeout
  //   const timer = setTimeout(() => {
  //     // Simulating incoming data
  //     const incomingData = {
  //       type: "dataframe",
  //       data: [
  //         { firstName: "PENELOPE", lastName: "GUINESS", movieTitle: "ACADEMY DINOSAUR" },
  //         { firstName: "PENELOPE", lastName: "GUINESS", movieTitle: "ANACONDA CONFESSIONS" },
  //         { firstName: "PENELOPE", lastName: "GUINESS", movieTitle: "ANGELS LIFE" },
  //         { firstName: "PENELOPE", lastName: "GUINESS", movieTitle: "BULWORTH COMMANDMENTS" },
  //         { firstName: "PENELOPE", lastName: "GUINESS", movieTitle: "CHEAPER CLYDE" },
  //         { firstName: "PENELOPE", lastName: "GUINESS", movieTitle: "COLOR PHILADELPHIA" },
  //         { firstName: "PENELOPE", lastName: "GUINESS", movieTitle: "ELEPHANT TROJAN" },
  //         { firstName: "PENELOPE", lastName: "GUINESS", movieTitle: "GLEAMING JAWBREAKER" },
  //         { firstName: "PENELOPE", lastName: "GUINESS", movieTitle: "HUMAN GRAFFITI" },
  //         { firstName: "PENELOPE", lastName: "GUINESS", movieTitle: "KING EVOLUTION" }
  //       ],
  //       // type: "text",
  //       // data: "This is a test output"
  //     }
  //     setOutputType(incomingData.type)
  //     setOutputData(incomingData.data)
  //   }, 1000)

  //   return () => clearTimeout(timer)
  // }, [])

  const renderOutput = () => {
    if (outputType === "text") {
      return (
        <Card
          sx={{ width: "300px", border: "1px solid #E5E7EB", margin: "0 auto" }}
        >
          <CardContent>
            <Typography variant="body2" className="text-sm text-gray-700">
              {outputData || "No output data available."}
            </Typography>
          </CardContent>
        </Card>
      );
    } else if (outputType === "dataframe") {
      return (
        <TableContainer
          component={Paper}
          sx={{ width: "100%", boxShadow: "none" }}
        >
          <Table size="small" sx={{ minWidth: "100%" }}>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#F8F9FA" }}>
                {outputData &&
                  outputData.length > 0 &&
                  Object.keys(outputData[0]).map((key) => (
                    <TableCell
                      key={key}
                      sx={{
                        fontWeight: 600,
                        textTransform: "uppercase",
                        fontSize: "0.75rem",
                        color: "#4B5563",
                        padding: "8px 16px",
                      }}
                    >
                      {key}
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {outputData &&
                outputData.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:nth-of-type(even)": { backgroundColor: "#F8F9FA" },
                      "&:hover": { backgroundColor: "#F3F4F6" },
                    }}
                  >
                    {Object.values(row).map((value, cellIndex) => (
                      <TableCell
                        key={cellIndex}
                        sx={{
                          fontSize: "0.875rem",
                          color: "#1F2937",
                          borderBottom: "1px solid #E5E7EB",
                          padding: "6px 16px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {value}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="relative">
        <Handle
          type="target"
          position="left"
          style={{ background: "#9333EA", width: "8px", height: "8px" }}
        />

        <Card sx={{ width: outputType === "text" ? 350 : 500 }}>
          <CardContent sx={{ padding: 0 }}>
            <div className="bg-[#F7F8FF80] mb-2.5">
              <div className="flex justify-between items-center py-1 px-2.5">
                <div className="flex items-center gap-2 font-bold">
                  <PiUploadSimpleBold size={18} className="font-bold" />
                  {isEditingTitle ? (
                    <TextField
                      variant="standard"
                      value={titleText}
                      onChange={handleTitleChange}
                      onBlur={handleEditEnd}
                      onKeyDown={handleTitleKeyDown}
                      autoFocus
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          fontSize: "1rem",
                          fontWeight: 500,
                          padding: 0,
                          height: "24px",
                        },
                      }}
                    />
                  ) : (
                    <Typography
                      variant="body1"
                      onClick={handleEditStart}
                      className="font-medium cursor-pointer"
                    >
                      {titleText}
                    </Typography>
                  )}
                </div>
                <IconButton
                  size="medium"
                  className="p-2"
                  onClick={handleEditStart}
                >
                  <TbEdit size={20} className="font-bold" />
                </IconButton>
              </div>
            </div>

            <div
              className="px-4 pb-4"
              style={{ maxHeight: "300px", overflowY: "auto" }}
            >
              {renderOutput()}
            </div>
          </CardContent>
        </Card>
      </div>
    </ThemeProvider>
  );
}
