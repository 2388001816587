// import React, { useEffect, useState } from 'react';
// import { getLogs } from '../services/api';
// import SearchBar from './SearchBar';
// import Filters from './Filters';
// import Timeline from './Timeline';
// import Pagination from './Pagination';

// const LogViewer = ({ userEmail }) => {
//   const [logs, setLogs] = useState([]);
//   const [filteredLogs, setFilteredLogs] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [logsPerPage] = useState(10);

//   useEffect(() => {
//     const fetchLogs = async () => {
//       const logs = await getLogs(userEmail);
//       setLogs(logs);
//       setFilteredLogs(logs);
//     };
//     fetchLogs();

//     // Simulate real-time updates
//     const interval = setInterval(fetchLogs, 5000); // Fetch logs every 5 seconds
//     return () => clearInterval(interval);
//   }, [userEmail]);

//   const handleSearch = (searchTerm) => {
//     const filtered = logs.filter((log) =>
//       log.message.toLowerCase().includes(searchTerm.toLowerCase())
//     );
//     setFilteredLogs(filtered);
//     setCurrentPage(1); // Reset to first page after search
//   };

//   const handleFilter = (filters) => {
//     let filtered = logs;
//     if (filters.level) {
//       filtered = filtered.filter((log) => log.message.includes(filters.level));
//     }
//     if (filters.startDate && filters.endDate) {
//       filtered = filtered.filter((log) => {
//         const logDate = new Date(log.timestamp);
//         return logDate >= new Date(filters.startDate) && logDate <= new Date(filters.endDate);
//       });
//     }
//     setFilteredLogs(filtered);
//     setCurrentPage(1); // Reset to first page after filtering
//   };

//   // Pagination logic
//   const indexOfLastLog = currentPage * logsPerPage;
//   const indexOfFirstLog = indexOfLastLog - logsPerPage;
//   const currentLogs = filteredLogs.slice(indexOfFirstLog, indexOfLastLog);

//   const paginate = (pageNumber) => setCurrentPage(pageNumber);

//   return (
//     <div className="p-8 bg-gray-100 min-h-screen">
//       <div className="max-w-7xl mx-auto">
//         <h1 className="text-3xl font-bold mb-8">Log Viewer</h1>
//         <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
//           <div className="md:col-span-1">
//             <Filters onFilter={handleFilter} />
//           </div>
//           <div className="md:col-span-3">
//             <SearchBar onSearch={handleSearch} />
//             <Timeline logs={filteredLogs} />
//             <div className="bg-white shadow rounded-lg p-6">
//               {currentLogs.map((log, index) => (
//                 <div key={index} className="border-b border-gray-200 py-2">
//                   <p className={log.message.includes('ERROR') ? 'text-red-500' : 'text-green-500'}>
//                     <span className="text-gray-500 text-sm">{log.timestamp}</span> - {log.message}
//                   </p>
//                 </div>
//               ))}
//               <Pagination
//                 logsPerPage={logsPerPage}
//                 totalLogs={filteredLogs.length}
//                 paginate={paginate}
//                 currentPage={currentPage}
//               />
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default LogViewer;


// import React, { useEffect, useState } from 'react';
// import { getLogs } from '../services/api';
// import SearchBar from './SearchBar';
// import Filters from './Filters';
// import Timeline from './Timeline';
// import Pagination from './Pagination';
// import moment from 'moment'; // Import moment for datetime formatting

// const LogViewer = ({ userEmail }) => {
//   const [logs, setLogs] = useState([]);
//   const [filteredLogs, setFilteredLogs] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [logsPerPage] = useState(10);

//   useEffect(() => {
//     const fetchLogs = async () => {
//       const logs = await getLogs(userEmail);
//       setLogs(logs);
//       setFilteredLogs(logs);
//     };
//     fetchLogs();

//     // Simulate real-time updates
//     const interval = setInterval(fetchLogs, 5000); // Fetch logs every 5 seconds
//     return () => clearInterval(interval);
//   }, [userEmail]);

//   const handleSearch = (searchTerm) => {
//     const filtered = logs.filter((log) =>
//       log.message.toLowerCase().includes(searchTerm.toLowerCase())
//     );
//     setFilteredLogs(filtered);
//     setCurrentPage(1); // Reset to first page after search
//   };

//   const handleFilter = (filters) => {
//     let filtered = logs;
//     if (filters.level) {
//       filtered = filtered.filter((log) => log.message.includes(filters.level));
//     }
//     if (filters.startDate && filters.endDate) {
//       filtered = filtered.filter((log) => {
//         const logDate = moment.utc(log.timestamp).local().toDate(); // Convert log timestamp to local timezone
//         const startDate = new Date(filters.startDate);
//         const endDate = new Date(filters.endDate);
//         return logDate >= startDate && logDate <= endDate;
//       });
//     }
//     setFilteredLogs(filtered);
//     setCurrentPage(1); // Reset to first page after filtering
//   };

//   // Pagination logic
//   const indexOfLastLog = currentPage * logsPerPage;
//   const indexOfFirstLog = indexOfLastLog - logsPerPage;
//   const currentLogs = filteredLogs.slice(indexOfFirstLog, indexOfLastLog);

//   const paginate = (pageNumber) => setCurrentPage(pageNumber);

//   return (
//     <div className="p-8 bg-gray-100 min-h-screen">
//       <div className="max-w-7xl mx-auto">
//         <h1 className="text-3xl font-bold mb-8">Log Viewer</h1>
//         <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
//           <div className="md:col-span-1">
//             <Filters onFilter={handleFilter} />
//           </div>
//           <div className="md:col-span-3">
//             <SearchBar onSearch={handleSearch} />
//             <Timeline logs={filteredLogs} />
//             <div className="bg-white shadow rounded-lg p-6">
//               {currentLogs.map((log, index) => (
//                 <div key={index} className="border-b border-gray-200 py-2">
//                   <p className={log.message.includes('ERROR') ? 'text-red-500' : 'text-green-500'}>
//                     {/* Convert timestamp to local timezone before displaying */}
//                     <span className="text-gray-500 text-sm">
//                       {moment.utc(log.timestamp).local().format('MMM D, YYYY HH:mm:ss')}
//                     </span> - {log.message}
//                   </p>
//                 </div>
//               ))}
//               <Pagination
//                 logsPerPage={logsPerPage}
//                 totalLogs={filteredLogs.length}
//                 paginate={paginate}
//                 currentPage={currentPage}
//               />
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default LogViewer;



import React, { useEffect, useState } from 'react';
import { getLogs } from '../services/api';
import SearchBar from './SearchBar';
import Filters from './Filters';
import Timeline from './Timeline';
import Pagination from './Pagination';
import moment from 'moment'; // Import moment for datetime formatting

const LogViewer = ({ userEmail }) => {
  const [logs, setLogs] = useState([]);
  const [filteredLogs, setFilteredLogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [logsPerPage] = useState(10);

  useEffect(() => {
    const fetchLogs = async () => {
      const logs = await getLogs(userEmail);
      setLogs(logs);
      setFilteredLogs(logs);
    };
    fetchLogs();

    // Simulate real-time updates
    const interval = setInterval(fetchLogs, 5000); // Fetch logs every 5 seconds
    return () => clearInterval(interval);
  }, [userEmail]);

  const handleSearch = (searchTerm) => {
    const filtered = logs.filter((log) =>
      log.message.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredLogs(filtered);
    setCurrentPage(1); // Reset to first page after search
  };

  const handleFilter = (filters) => {
    let filtered = logs;
    if (filters.level) {
      filtered = filtered.filter((log) => log.message.includes(filters.level));
    }
    if (filters.startDate && filters.endDate) {
      filtered = filtered.filter((log) => {
        const logDate = moment.utc(log.timestamp).local().toDate(); // Convert log timestamp to local timezone
        const startDate = new Date(filters.startDate);
        const endDate = new Date(filters.endDate);
        return logDate >= startDate && logDate <= endDate;
      });
    }
    setFilteredLogs(filtered);
    setCurrentPage(1); // Reset to first page after filtering
  };

  // Pagination logic
  const indexOfLastLog = currentPage * logsPerPage;
  const indexOfFirstLog = indexOfLastLog - logsPerPage;
  const currentLogs = filteredLogs.slice(indexOfFirstLog, indexOfLastLog);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="p-8 bg-gray-100 min-h-screen">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-3xl font-bold mb-8">Log Viewer</h1>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div className="md:col-span-1">
            <Filters onFilter={handleFilter} />
          </div>
          <div className="md:col-span-3">
            <SearchBar onSearch={handleSearch} />
            <Timeline logs={filteredLogs} />
            <div className="bg-white shadow rounded-lg p-6">
              {/* Log Box Headers */}
              <div className="grid grid-cols-12 gap-4 font-semibold border-b border-gray-200 pb-2 mb-4">
                <div className="col-span-2">Time</div>
                <div className="col-span-10">Source</div>
              </div>

              {/* Log Entries */}
              {currentLogs.map((log, index) => (
                <div key={index} className="grid grid-cols-12 gap-4 border-b border-gray-200 py-2">
                  {/* Time Section */}
                  <div className="col-span-2 text-gray-500 text-sm">
                    {moment.utc(log.timestamp).local().format('MMM D, YYYY HH:mm:ss')}
                  </div>

                  {/* Source Section */}
                  <div className="col-span-10">
                    <p className={log.message.includes('ERROR') ? 'text-red-500' : 'text-green-500'}>
                      {log.message}
                    </p>
                  </div>
                </div>
              ))}

              {/* Pagination */}
              <Pagination
                logsPerPage={logsPerPage}
                totalLogs={filteredLogs.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogViewer;