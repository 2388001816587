import { useState } from "react";
import { PiDownloadSimpleBold, PiUploadSimpleBold } from "react-icons/pi";
import { HiOutlineDatabase } from "react-icons/hi";
import { BsTextareaResize } from "react-icons/bs";
import { HiOutlineWindow } from "react-icons/hi2";
import { RiWindowFill } from "react-icons/ri";
import { PiUserCircleFill, PiFileSql } from "react-icons/pi";
import { FaCodeBranch } from "react-icons/fa";
import { IoChevronDownSharp, IoChevronForwardSharp } from "react-icons/io5";
import qubridLogo from "../../assets/agentslogos/qubridL.svg";
import nvidiaLogo from "../../assets/agentslogos/Nvdia_Icon.svg";
import huggingFaceLogo from "../../assets/agentslogos/hugging-face.svg";

const components = [
  {
    id: "input",
    label: "Input",
    icon: PiDownloadSimpleBold,
    type: "inputNode",
  },
  {
    id: "output",
    label: "Output",
    icon: PiUploadSimpleBold,
    type: "outputNode",
  },
  {
    id: "prompt",
    label: "Prompt",
    icon: RiWindowFill,
    type: "promptNode",
  },
  {
    id: "agent",
    label: "Evaluation Agent",
    icon: PiUserCircleFill,
    type: "agentNode",
  },
  {
    id: "models",
    label: "Models",
    type: "modelNode",
    subComponents: [
      {
        id: "qubridLLM",
        label: "Qubrid LLM",
        icon: qubridLogo,
        type: "qubridLLMNode",
      },
      {
        id: "huggingfaceLLM",
        label: "HuggingFace LLM",
        icon: huggingFaceLogo,
        type: "huggingfaceLLMNode",
      },
      {
        id: "nvidiaNimLLM",
        label: "Nvidia NIM LLM",
        icon: nvidiaLogo,
        type: "nvidiaNimLLMNode",
      },
    ],
  },
  {
    id: "databaseConnection",
    label: "Database Connection",
    icon: HiOutlineDatabase,
    type: "databaseConnectionNode",
  },
  {
    id: "sqldatabse",
    label: "SQL Database",
    icon: PiFileSql,
    type: "sqlQueryNode",
  },
  {
    id: "sqlQueryGenerator",
    label: "SQL Query Executor",
    icon: PiFileSql,
    type: "sqlQueryGeneratorNode",
  },
  {
    id: "ifElse",
    label: "If Else",
    icon: FaCodeBranch,
    type: "ifElseNode",
  },
  {
    id: "textArea",
    label: "Text Area",
    icon: BsTextareaResize,
    type: "textAreaNode",
  },
];

const Toolbar = () => {
  const [isModelsOpen, setIsModelsOpen] = useState(true);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.effectAllowed = "move";
  };

  return (
    <div
      className={`bg-white p-4 w-[280px] max-h-[calc(100vh-175px)] fixed left-[106px] z-10 shadow-lg rounded-md flex flex-col mt-4 transition-all duration-300 ${
        isCollapsed ? "w-8 h-14" : "w-[280px]"
      }`}
    >
      <div
        className="flex items-center justify-between mb-4 cursor-pointer"
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <h2 className="text-base font-semibold">Components</h2>
        <button
          className="rounded-md hover:bg-gray-100"
          aria-label={isCollapsed ? "Expand components" : "Collapse components"}
        >
          {isCollapsed ? (
            <IoChevronForwardSharp size={20} />
          ) : (
            <IoChevronDownSharp size={20} />
          )}
        </button>
      </div>
      <div
        className={`space-y-2 overflow-y-auto pr-4 -mr-4 ${
          isCollapsed ? "hidden" : ""
        }`}
      >
        {components.map((component) => {
          if (component.id === "models") {
            return (
              <div key={component.id} className="space-y-2">
                <div
                  className="flex items-center justify-between p-2 bg-[#FAFBFE4D] rounded-md border border-[#E7E7E7CC] shadow-sm hover:bg-gray-50 cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsModelsOpen(!isModelsOpen);
                  }}
                >
                  <div className="flex items-center gap-2 font-medium text-gray-700">
                    <HiOutlineWindow size={20} />
                    <span>{component.label}</span>
                  </div>
                  {isModelsOpen ? (
                    <IoChevronDownSharp size={20} />
                  ) : (
                    <IoChevronForwardSharp size={20} />
                  )}
                </div>
                {isModelsOpen && (
                  <div className="pl-4 space-y-2">
                    {component.subComponents.map((subComponent) => (
                      <div
                        key={subComponent.id}
                        className="flex items-center gap-2 p-2 bg-[#FAFBFE4D] rounded-md border border-[#E7E7E7CC] shadow-sm hover:bg-gray-50 cursor-move"
                        draggable
                        onDragStart={(event) =>
                          onDragStart(event, subComponent.type)
                        }
                      >
                        <img
                          src={subComponent.icon}
                          alt="subcompicon"
                          className="size-5"
                        />
                        <span className="text-sm">{subComponent.label}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            );
          }

          const IconComponent = component.icon;
          return (
            <div
              key={component.id}
              className="flex items-center gap-2 p-2 bg-[#FAFBFE4D] rounded-md border border-[#E7E7E7CC] shadow-sm hover:bg-gray-50 cursor-move"
              draggable
              onDragStart={(event) => onDragStart(event, component.type)}
            >
              <IconComponent size={20} />
              <span>{component.label}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Toolbar;
