import { useCallback, useState } from "react";
import {
  Card,
  CardContent,
  TextField,
  IconButton,
  Typography,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { Handle } from "reactflow";
import { TbEdit } from "react-icons/tb";
import CustomSlider from "./CustomSlider";
import qubridLogo from "../../../../assets/agentslogos/qubridL.svg";
import { useNodeData } from "../../NodeDataContext";

const theme = createTheme({
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow:
            "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
          borderRadius: "6px",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "16px",
          "&:last-child": { paddingBottom: "16px" },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "& fieldset": { borderColor: "#E5E7EB" },
            "&:hover fieldset": { borderColor: "#E5E7EB" },
            "&.Mui-focused fieldset": { borderColor: "#9333EA" },
          },
        },
      },
    },
  },
});

export default function QubridLLM({ id }) {
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const { nodeData, updateNodeData } = useNodeData();

  const titleText = nodeData[id]?.titleText || "Qubrid LLM";
  const maxTokens = nodeData[id]?.maxTokens || 500;
  const temperature = nodeData[id]?.temperature || 0.7;
  const topP = nodeData[id]?.topP || 1;
  const repetitionPenalty = nodeData[id]?.repetitionPenalty || 1;
  const input = nodeData[id]?.input || "";
  const modelName = nodeData[id]?.modelName || "gemma-2-2B-instruct";
  const apiKey = nodeData[id]?.apiKey || "****************";

  const handleEditStart = () => setIsEditingTitle(true);
  const handleTitleChange = (e) => {
    updateNodeData(id, { ...nodeData[id], titleText: e.target.value });
  };
  const handleEditEnd = () => setIsEditingTitle(false);
  const handleTitleKeyDown = (e) => {
    if (e.key === "Enter") handleEditEnd();
  };

  const handleInputChange = useCallback(
    (e) => {
      updateNodeData(id, { ...nodeData[id], input: e.target.value });
    },
    [id, nodeData, updateNodeData]
  );

  const handleModelNameChange = useCallback(
    (e) => {
      updateNodeData(id, { ...nodeData[id], modelName: e.target.value });
    },
    [id, nodeData, updateNodeData]
  );

  const handleApiKeyChange = useCallback(
    (e) => {
      updateNodeData(id, { ...nodeData[id], apiKey: e.target.value });
    },
    [id, nodeData, updateNodeData]
  );

  const handleSliderChange = useCallback(
    (key, value) => {
      updateNodeData(id, { ...nodeData[id], [key]: value });
    },
    [id, nodeData, updateNodeData]
  );
  return (
    <ThemeProvider theme={theme}>
      <div className="relative">
        <Handle
          type="target"
          position="left"
          style={{ background: "#9333EA", width: "8px", height: "8px" }}
        />

        <Handle
          type="source"
          position="right"
          style={{ background: "#9333EA", width: "8px", height: "8px" }}
        />

        <Card
          sx={{ width: 300, border: "1px solid #E5E7EB", overflow: "hidden" }}
        >
          <CardContent sx={{ padding: 0 }}>
            <div className="bg-[#F7F8FF80] mb-2.5">
              <div className="flex justify-between items-center py-1 px-2.5">
                <div className="flex items-center gap-2 font-bold">
                  <img
                    src={qubridLogo || "/placeholder.svg"}
                    alt="Qubrid"
                    className="w-6 h-6"
                  />
                  {isEditingTitle ? (
                    <TextField
                      variant="standard"
                      value={titleText}
                      onChange={handleTitleChange}
                      onBlur={handleEditEnd}
                      onKeyDown={handleTitleKeyDown}
                      autoFocus
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          fontSize: "1rem",
                          fontWeight: 500,
                          padding: 0,
                          height: "24px",
                        },
                      }}
                    />
                  ) : (
                    <Typography
                      variant="body1"
                      onClick={handleEditStart}
                      className="font-medium cursor-pointer"
                    >
                      {titleText}
                    </Typography>
                  )}
                </div>
                <IconButton
                  size="medium"
                  className="p-2"
                  onClick={handleEditStart}
                >
                  <TbEdit size={20} className="font-bold" />
                </IconButton>
              </div>
            </div>

            <div
              className="px-4 pb-4 space-y-2 overflow-hidden"
              onMouseDown={(e) => e.stopPropagation()}
            >
              <div>
                <Typography
                  variant="body2"
                  className="text-sm font-medium text-gray-700 mb-1"
                >
                  Input <span className="text-red-500">*</span>
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Type something..."
                  multiline
                  rows={2}
                  size="small"
                  value={input}
                  onChange={handleInputChange}
                  InputProps={{
                    style: { fontSize: "0.875rem" },
                  }}
                />
              </div>

              <div>
                <Typography
                  variant="body2"
                  className="text-sm font-medium text-gray-700 mb-1"
                >
                  Model Name <span className="text-red-500">*</span>
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={modelName}
                  onChange={handleModelNameChange}
                  size="small"
                  InputProps={{
                    style: { fontSize: "0.875rem" },
                  }}
                />
              </div>

              <div>
                <Typography
                  variant="body2"
                  className="text-sm font-medium text-gray-700 mb-1"
                >
                  API Key <span className="text-red-500">*</span>
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="password"
                  value={apiKey}
                  onChange={handleApiKeyChange}
                  size="small"
                  InputProps={{
                    style: { fontSize: "0.875rem" },
                  }}
                />
              </div>

              <div className="space-y-1">
                <CustomSlider
                  label="Max New Tokens"
                  value={maxTokens}
                  setValue={(value) => handleSliderChange("maxTokens", value)}
                  min={100}
                  max={2000}
                  step={50}
                />

                <CustomSlider
                  label="Temperature"
                  value={temperature}
                  setValue={(value) => handleSliderChange("temperature", value)}
                  min={0}
                  max={2}
                  step={0.01}
                />

                <CustomSlider
                  label="Top P"
                  value={topP}
                  setValue={(value) => handleSliderChange("topP", value)}
                  min={0}
                  max={1}
                  step={0.01}
                />

                <CustomSlider
                  label="Repetition Penalty"
                  value={repetitionPenalty}
                  setValue={(value) =>
                    handleSliderChange("repetitionPenalty", value)
                  }
                  min={1}
                  max={2}
                  step={0.01}
                />
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </ThemeProvider>
  );
}
