import React from 'react';

const Pagination = ({ logsPerPage, totalLogs, paginate, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalLogs / logsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="flex justify-center mt-6">
      <nav>
        <ul className="flex space-x-2">
          {pageNumbers.map((number) => (
            <li key={number}>
              <button
                onClick={() => paginate(number)}
                className={`px-4 py-2 ${
                  currentPage === number
                    ? 'bg-blue-500 text-white'
                    : 'bg-white text-blue-500'
                } border border-blue-500 rounded-lg hover:bg-blue-600 hover:text-white`}
              >
                {number}
              </button>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default Pagination;
